import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export default function MetodosPago(props) {
    return (
        <>
            <div className="pb-3 text-center text-secondary"><b>Datos del usuario:</b></div>
            <p className="text-center"><span className="text-secondary">Nombre:</span> {props.user.nombre} {props.user.apellidos} / <span className="text-secondary">DNI:</span> {props.user.dni} / <span className="text-secondary">Email:</span> {props.user.email}</p>
            <div className="mb-4 text-center"><button className="btn btn-primary btn-sm" onClick={() => props.handleShowType("login")}>Cambiar de usuario</button></div>
            <div className="pb-3 text-center text-secondary"><b>Métodos de pago:</b></div>
            <Row className="text-center">
                <Col md={6}>
                    <div className="border rounded m-1 p-4 metodopago">
                        <div className="mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                                 className="bi bi-credit-card-2-back" viewBox="0 0 16 16">
                                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/>
                                <path
                                    d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/>
                            </svg>
                        </div>
                        <div>
                            <b>TARJETA</b>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="border rounded m-1 p-4 metodopago">
                        <div className="mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                                 className="bi bi-envelope-at" viewBox="0 0 16 16">
                                <path
                                    d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                                <path
                                    d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                            </svg>
                        </div>
                        <div>
                            <b>TRANSFERENCIA</b>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}