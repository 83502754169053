import logo from "../../opositalogo_menu.avif";
import FiltrosHeader from "./header/FiltrosHeader";

export default function Header(props) {

    return (
        <div>
            <div>
                <div className="text-white p-1 ps-5 pe-5 text-center top-header">
                    <div className="row ">
                        <div className="col-md-9 col-lg-9 text-md-start">
                            <a href="/"><img src={logo} alt="Logo Oposita Formación" title="Logo Oposita Formación" id="logo"
                                             className="pt-1 pb-2" />
                            </a>
                        </div>
                        <FiltrosHeader error={props.error} categorias={props.categorias} comunidades={props.comunidades} isLoaded={props.isLoaded}></FiltrosHeader>
                    </div>
                </div>
            </div>
            <div className="text-end pt-4 pb-5 bg-fpprime-gradient" id="search-container">
            </div>

        </div>
    );

}
