import React from 'react';
import ModalInfoCursoView from "../modals/ModalInfoCurso";

export default function MasInfoCursoView() {

    /**************
     *   RETURN   *
     **************/
    return (
        <div className = "d-lg-block solicitainfo" id="btn-solicita-info">
            <section className="container-form bloque-form-homes">
                <div className="d-sm-block bg-grey mt-90">
                    <div className="d-none d-lg-block container-img-form">

                    </div>
                    <ModalInfoCursoView />
                </div>
            </section>
        </div>
    );
}
