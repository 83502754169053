import React, {useEffect, useState} from 'react';
import config from "../../../config/apiConfig";

const Curso = ({ titulo, empresa, id, idEmpresa, categoria, imagen, empresaAcreditadora }) => {
    return (
        <div className="col-sm-3 container-text-aligned text-center vistamenucurso pt-4" key={id}>
            <img alt="" src={imagen} className="text-center circle-fpprime-img2"></img>
            <h5 className="pe-3 ps-3">{titulo}</h5>
            <div className="text-center txt-gray"> Categoría: <span className="text-dark-yellow"> {categoria} </span></div>
            {/*<div className="text-center txt-gray"> Impartido por: <span className="text-yellow"> {empresa} </span></div>*/}
            {/*<div className="text-center txt-gray"> Acreditado por: <span className="text-yellow"> {empresaAcreditadora} </span></div>*/}
            <div className="elegant-line-bottom alto-20"></div>
            <a className="sabermas text-decoration-none" href={`/curso/${idEmpresa}/${id}`}><b className="text-yellow sabermas font-600">Saber más</b></a>
        </div>
    );
}

const CursoCategoriasList = ({idcategoria, categoria}) => {

    const aux = 43;
    const [categoriasCursoEmpresa, setCategoriasCursoEmpresa] = useState([]);
    const data = {
        action: 'devuelveCursosCategoria',
        idEmp: aux,
    };

    useEffect(() => {
        // En vez de categorias tengo que devolver cursos
        fetch(config.API_BASE_URL + '/curso.php?idempresa=43&trcce_id='+idcategoria, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                // Recorremos el array de cursos y llamamos al método que genera el html con los datos
                setCategoriasCursoEmpresa(result);
            })
            .catch(error => {
                // Manejar errores de la solicitud
                console.error('Error:', error);
            });
    }, [categoria]); // Obtener datos cuando cambia categoria

    const renderCursosRows = () => {
        if(categoriasCursoEmpresa) {
            return categoriasCursoEmpresa.map(curso => (
                <Curso
                    key={curso.trcce_id}
                    id={curso.trcce_id}
                    idEmpresa={curso.idempresa}
                    empresa={curso.nombreempresa}
                    empresaAcreditadora={curso.nombreentidadacreditadora}
                    titulo={curso.nombrecursoacreditado}
                    categoria={curso.nombrecategoria}
                    imagen={curso.imagen}
                />
            ));
        }
    };

    return (
        <div className="me-lg-5 ms-lg-5 pe-5 ps-5">
            <div className="row mt-lg-5 me-0 ms-0 justify-content-center curso-row" >
                {renderCursosRows()}
            </div>
        </div>
    );
};

export default CursoCategoriasList;
