import React, { useState } from 'react';
import config from './../../config/apiConfig';
import {AiOutlineArrowLeft} from "react-icons/ai";

const Registro = (props) => {
    const [nombre, setNombre] = useState('');
    const [apellido1, setApellido1] = useState('');
    const [apellido2, setApellido2] = useState('');
    const [email, setEmail] = useState('');
    const [dni, setDNI] = useState('');
    const [direccionPostal, setDireccionPostal] = useState('');
    const [confirmaEmail, setConfirmaEmail] = useState('');
    const [localidad, setLocalidad] = useState('');
    const [provincia, setProvincia] = useState('');
    const [codpost, setCodPost] = useState('');
    const [telefono, setTelefono] = useState('');
    const [nacionalidad, setNacionalidad] = useState(60);
    const [nacimiento, setNacimiento] = useState('');
    const [ocupacionLaboral, setOcupacionLaboral] = useState('');
    const [sexo, setSexo] = useState('');
    const [conoce, setConoce] = useState('');
    const [aceptarLOPD, setAceptarLOPD] = useState(false);
    const [enviardatos, setEnviarDatos] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        if(nombre==="" || apellido1==="" || dni==="" || sexo==="" || direccionPostal==="" || localidad==="" || provincia==="" || codpost==="" || nacionalidad==="" || telefono==="" || nacimiento==="" || email==="")
        {
            console.log(nombre+"-"+apellido1+"-"+dni+"-"+sexo+"-"+direccionPostal+"-"+localidad+"-"+provincia+"-"+codpost+"-"+nacionalidad+"-"+telefono+"-"+nacimiento+"-"+email);
            props.handleShowAlert("danger","Hay campos vacíos");
        }
        else if (email !== confirmaEmail) {
            props.handleShowAlert("danger","El email no coincide");
        }
        else if(!aceptarLOPD) {
            props.handleShowAlert("danger","No has aceptado las condiciones");
        }
        else
        {
            // Datos del formulario
            const formData = {
                nombre: nombre,
                apellido1: apellido1,
                apellido2: apellido2,
                dni: dni,
                sexo: sexo,
                direccionPostal: direccionPostal,
                localidad: localidad,
                provincia: provincia,
                codpost: codpost,
                nacionalidad: nacionalidad,
                telefono: telefono,
                ocupacionLaboral: ocupacionLaboral,
                nacimiento: nacimiento,
                email: email,
                conoce: conoce,
                codigoempresa: "8942LP29432PQ44"
            };

            // Realizar la solicitud HTTP POST
            fetch(config.API_BASE_URL+ '/registro.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(data => {
                    if(data.response.success===true)
                    {
                        props.handleShowType("paymethods");
                        props.handleShowAlert("success",data.response.title,data.response.msg);
                        props.handleUser(data.user);
                    }
                    else
                    {
                        props.handleShowAlert("danger",data.response.title,data.response.msg);
                    }
                })
                .catch(error => {
                    // Manejar errores de la solicitud
                    console.error(error);
                });
        }
    };

    const handleLOPDChange = () => {
        setAceptarLOPD(!aceptarLOPD);
    };

    return (
        <div className="pt-0 bg-primary text-white">
            <p>
                <button className="btn btn-link link-light text-decoration-none" onClick={() => props.handleShowType("login")}> <AiOutlineArrowLeft />
                    Volver a login</button></p>
            <form onSubmit={handleSubmit}  className="ng-pristine ng-valid p-5 pt-0 text-start">
                <div className="row text-center mt-1">
                    <h3 className="col-md-12 mb-4 text-blue">Introduce tus datos</h3>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="nombre">Nombre</label>
                        <input type="hidden" name="accion" value="crearalumno" autoComplete="off" />
                        <input type="text"
                               className="form-control w-100"
                               name="nombre"
                               id="nombre"
                               required={true}
                               value={nombre}
                               onChange={(e) => setNombre(e.target.value)}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="apellido1">Primer apellido</label>
                        <input type="text"
                               className="form-control w-100"
                               name="apellido1"
                               id="apellido1"
                               required={true}
                               value={apellido1}
                               onChange={(e) => setApellido1(e.target.value)}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="apellido2">Segundo apellido</label>
                        <input type="text"
                               className="form-control w-100"
                               name="apellido2"
                               id="apellido2"
                               value={apellido2}
                               onChange={(e) => setApellido2(e.target.value)}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="dni">DNI / NIE / Pasaporte</label>
                        <input type="text"
                               name="dni"
                               className="form-control w-100"
                               id="dni"
                               value={dni}
                               onChange={(e) => setDNI(e.target.value)}
                               required={true}
                               pattern="[XxYyZz]?[0-9]{7,8}[A-Za-z]"
                               placeholder="DNI/NIE" />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="email">Email</label>
                        <input type="email"
                               className="form-control w-100"
                               name="email"
                               id="email"
                               required={true}
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="confirmaEmail">Introduce de nuevo tu email</label>
                        <input type="email"
                               className="form-control w-100"
                               name="confirmaEmail"
                               id="confirmaEmail"
                               required={true}
                               value={confirmaEmail}
                               onChange={(e) => setConfirmaEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="direccionPostal">Dirección postal</label>
                        <input type="text"
                               className="form-control w-100"
                               name="direccionPostal"
                               id="direccionPostal"
                               value={direccionPostal}
                               onChange={(e) => setDireccionPostal(e.target.value)}
                               required={true}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="localidad">Localidad</label>
                        <input type="text"
                               className="form-control w-100"
                               name="localidad"
                               id="localidad"
                               value={localidad}
                               onChange={(e) => setLocalidad(e.target.value)}
                               required={true}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="provincia">Provincia</label>
                        <select name="provincia"
                                className="form-control w-100"
                                value={provincia}
                                onChange={(e) => setProvincia(e.target.value)}
                                required={true}
                        >
                            <option value="" disabled="">Escoge provincia</option>
                            <option value="albacete">﻿Albacete</option>
                            <option value="alicante">Alicante/Alacant</option>
                            <option value="almeria">Almería</option>
                            <option value="alava">Araba/Álava</option>
                            <option value="asturias">Asturias</option>
                            <option value="avila">Ávila</option>
                            <option value="badajoz">Badajoz</option>
                            <option value="illesbalears">Balears (Illes)</option>
                            <option value="barcelona">Barcelona</option>
                            <option value="vizcaya">Bizkaia</option>
                            <option value="burgos">Burgos</option>
                            <option value="caceres">Cáceres</option>
                            <option value="cadiz">Cádiz</option>
                            <option value="cantabria">Cantabria</option>
                            <option value="castellon">Castellón/Castelló</option>
                            <option value="ceuta">Ceuta</option>
                            <option value="ciudadreal">Ciudad Real</option>
                            <option value="cordoba">Córdoba</option>
                            <option value="acoruña">Coruña (A)</option>
                            <option value="cuenca">Cuenca</option>
                            <option value="guipuzcoa">Gipuzkoa</option>
                            <option value="girona">Girona</option>
                            <option value="granada">Granada</option>
                            <option value="guadalajara">Guadalajara</option>
                            <option value="huelva">Huelva</option>
                            <option value="huesca">Huesca</option>
                            <option value="jaen">Jaén</option>
                            <option value="leon">León</option>
                            <option value="lleida">Lleida</option>
                            <option value="lugo">Lugo</option>
                            <option value="madrid">Madrid</option>
                            <option value="malaga">Málaga</option>
                            <option value="melilla">Melilla</option>
                            <option value="murcia">Murcia</option>
                            <option value="navarra">Navarra</option>
                            <option value="ourense">Ourense</option>
                            <option value="palencia">Palencia</option>
                            <option value="laspalmas">Palmas (Las)</option>
                            <option value="pontevedra">Pontevedra</option>
                            <option value="larioja">Rioja (La)</option>
                            <option value="salamanca">Salamanca</option>
                            <option value="santacruztenerife">Santa Cruz de Tenerife</option>
                            <option value="segovia">Segovia</option>
                            <option value="sevilla">Sevilla</option>
                            <option value="soria">Soria</option>
                            <option value="tarragona">Tarragona</option>
                            <option value="teruel">Teruel</option>
                            <option value="toledo">Toledo</option>
                            <option value="valencia">Valencia/València</option>
                            <option value="valladolid">Valladolid</option>
                            <option value="zamora">Zamora</option>
                            <option value="zaragoza">Zaragoza</option>
                        </select>
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="codpost">Código Postal</label>
                        <input type="number"
                               className="form-control w-100"
                               name="codpost"
                               id="codpost"
                               value={codpost}
                               onChange={(e) => setCodPost(e.target.value)}
                               required={true}
                               maxLength="5"/>
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="telefono">Teléfono</label>
                        <input
                            type="tel"
                            className="form-control w-100"
                            name="telefono"
                            id="telefono"
                            value={telefono}
                            onChange={(e) => setTelefono(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="ocupacionLaboral">Ocupación laboral</label>
                        <input type="text"
                               className="form-control w-100"
                               name="ocupacionLaboral"
                               id="ocupacionLaboral"
                               value={ocupacionLaboral}
                               onChange={(e) => setOcupacionLaboral(e.target.value)}
                               required={true}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="nacionalidad">Nacionalidad</label>
                        <select className="form-select" name="nacionalidad" id="nacionalidad" required={true} onChange={(e) => setNacionalidad(e.target.value)} defaultValue="60">
                            {Object.values(props.nacionalidades).map((nac, index) => (
                                <option key={index} value={nac.id}>{nac.GENTILICIO_NAC} ({nac.ISO_NAC})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="nacimiento">Fecha de nacimiento</label>
                        <input type="date"
                               className="form-control w-100"
                               name="nacimiento"
                               id="nacimiento"
                               value={nacimiento}
                               onChange={(e) => setNacimiento(e.target.value)}
                               required={true}
                        />
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start">
                        <label htmlFor="sexo">Sexo</label>
                        <select name="sexo"
                                className="form-control w-100"
                                required={true}
                                value={sexo}
                                onChange={(e) => setSexo(e.target.value)}>
                            <option value="" disabled="">Seleccione su sexo</option>
                            <option value="0">Hombre</option>
                            <option value="1">Mujer</option>
                        </select>
                    </div>
                    <div className="form-group col-lg-6 mb-3 text-start text-start">
                        <label htmlFor="conoce">¿Cómo nos ha conocido?</label>
                        <input type="text"
                               name="conoce"
                               className="form-control w-100"
                               id="conoce"
                               value={conoce}
                               onChange={(e) => setConoce(e.target.value)}
                               required={true}
                        />
                    </div>

                    <div className="col-md-12 text-center mt-4">
                        <div className="checkbox mb-4">
                            <input required="" type="checkbox" name="condiciones" id="condiciones"
                                   value="1" className="form-check-input me-2" checked={aceptarLOPD} onChange={handleLOPDChange} />
                            Acepto los <a
                                          data-toggle="modal"
                                          data-target="#modalterminoscondiciones" className="link-light">términos
                            y condiciones</a> en lo referente a la LOPD y las <a data-toggle="modal"
                            data-target="#modalcondicionescontratacion" className="link-light">condiciones de
                            contratación. </a>
                        </div>
                    </div>
                    <div className="col-md-12 text-center m-4">
                        <button type="submit" id="registraAlumno"
                                className="botonCrearAlumno btn btn-primary">Crear alumno
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Registro;