import {useEffect, useState} from "react";
import config from './../../config/apiConfig';
import Login from './Login.js';
import Registro from './Registro.js';
import {Alert} from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import Spinner from "react-bootstrap/Spinner";
import {useParams} from "react-router-dom";
import MetodosPago from "./MetodosPago";

export default function Body() {
    let param = useParams();
    const [error, setError]         = useState(null);
    const [isLoaded, setIsLoaded]   = useState(false);
    const [user, setUser] = useState([]);
    const [oposicion, setOposicion] = useState([]);
    const [convocatoria, setConvocatoria] = useState([]);
    const [nacionalidades, setNacionalidades] = useState([]);
    const [show, setShow]           = useState(true);

    useEffect(() => {
        fetch(config.API_BASE_URL+ '/pedidos.php?codigoempresa=8942LP29432PQ44&trcce_id='+param.trcce_id+'&conv_id='+param.conv_id, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
        })
            .then(response => response.json())
            .then((result) => {
                setIsLoaded(true);
                setUser(result.user_data);
                setOposicion(result.oposicion_data);
                setNacionalidades(result.nacionalidades);
                setConvocatoria(result.convocatoria);
                setshowType(result.user_data.length === 0? "login" : "acceso");
            })
            .catch(error => {
                setIsLoaded(true);
                setError(error);
            });
    }, [param.trcce_id,param.conv_id])

    const [showType, setshowType] = useState("login");
    const [showAlertTipo, setshowAlertTipo] = useState(false);
    const [showAlertTexto, setshowAlertTexto] = useState(false);
    const [showAlertMensaje, setshowAlertMensaje] = useState(false);
    const handleShowType = (estado) => {
        setshowType(estado);
    };
    const handleShowAlert = (tipo,texto,mensaje) => {
        setshowAlertTipo(tipo);
        setshowAlertTexto(texto);
        setshowAlertMensaje(mensaje);
    };
    const handleUser = (user) => {
        setUser(user);
    };

    if (error) {
        return (
            <ToastContainer className="p-3" position="middle-center">
                <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide>
                    <Toast.Header>
                        Error al cargar el módulo
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        )
    } else if (!isLoaded) {
        return (
            <ToastContainer className="p-3 position-fixed" position="middle-center" >
                <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide bg="secondary">
                    <Toast.Header>
                        <Spinner animation="border" variant="dark" className="me-3"/>
                        <strong className="me-auto">Cargando oposición</strong>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        );
    } else {
        return (
            <div className="p-2 bg-fpprime-gradient h-100 w-100 position-fixed">
                <div className="row mb-5 h-100">
                    <div className="col-lg-5 m-auto text-align-center light-gradient mb-5 h-100 p-0">
                        <div className="p-5 pb-3 overflow-auto h-85">
                            <h2 className="mb-4 pb-4 border-bottom border-3">Detalles del pedido</h2>
                            <h5 className="mb-3 fw-bold text-secondary">{oposicion.nombrecursoacreditado}</h5>
                            <p><strong>Convocatorias: </strong> Del {new Date(convocatoria.fechainicio).toLocaleDateString()} al {new Date(convocatoria.fechafin).toLocaleDateString()} </p>
                            <p><strong>Precio{oposicion.precio > oposicion.precio_final ? " sin promoción":""}: </strong> {oposicion.precio}€ </p>
                            {
                                oposicion.precio > oposicion.precio_final ? <p className="pb-4"><strong>PRECIO FINAL aplicando descuento "{oposicion.descuento}": </strong> {oposicion.precio_final}€ </p> : <></>
                            }
                            {showAlertTipo ? <Alert key={showAlertTipo} variant={showAlertTipo}><b>{showAlertTexto}:</b> {showAlertMensaje}</Alert> : ""}
                            <div>
                                {
                                    showType === "login" ? <Login handleShowType={handleShowType} handleShowAlert={handleShowAlert} handleUser={handleUser} /> : (
                                        showType === "register" ? <Registro handleShowType={handleShowType} handleShowAlert={handleShowAlert} handleUser={handleUser} nacionalidades ={nacionalidades} /> :
                                            <MetodosPago user={user} handleShowType={handleShowType}/>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
