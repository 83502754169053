
export default function validaDatos(name, email, phone,checkboxchecked, recaptcha, modalType) {

    let select = "";
    let helpBlock = "";
    let emailForm = "";
    let helpBlockMail = "";
    let helpBlockPhone = "";
    let helpBlockcheckbox = "";
    let helpBlockrecapt = "";
    if(modalType === "preinscripcion") {
        select = document.getElementById("selectorCurso2");
        helpBlock = document.getElementById("formacion-contact-help2");
        emailForm = document.getElementById("email-contact2");
        helpBlockMail = document.getElementById("email-contact-help2");
        helpBlockPhone = document.getElementById("phone-contact-help2");
        helpBlockcheckbox = document.getElementById("msgacept2");
        helpBlockrecapt = document.getElementById("recaptgacept2");
    }
    else {
        select = document.getElementById("selectorCurso");
        helpBlock = document.getElementById("formacion-contact-help");
        emailForm = document.getElementById("email-contact");
        helpBlockMail = document.getElementById("email-contact-help");
        helpBlockPhone = document.getElementById("phone-contact-help");
        helpBlockcheckbox = document.getElementById("msgacept");
        helpBlockrecapt = document.getElementById("recaptgacept");
    }

    let todook = true;

    if(select && select.value !== "") {
        if (select.classList.contains("is-invalid")) {
            helpBlock.innerHTML = "";
            select.classList.remove("is-invalid");
        }
    }
    else {
        helpBlock.innerHTML = "Escoge la formación en la que estás interesado";
        select.classList.add("is-invalid");
        todook = false;
    }

    if(validaEmail(email)) {
        if (emailForm.classList.contains("is-invalid")) {
            helpBlockMail.innerHTML = "";
            emailForm.classList.remove("is-invalid");
        }
    }
    else {
        helpBlockMail.innerHTML = "El correo electrónico introducido no es válido. Recuerda que el formato correcto es 'nombre@dominio.com'";
        emailForm.classList.add("is-invalid");
        todook = false;
    }
    if (validarTelefonoCompleto(phone)) {
        helpBlockPhone.innerHTML = "";
    } else {
        helpBlockPhone.innerHTML = "Introduce un número de teléfono válido";
        todook = false;
    }

    if(checkboxchecked) {
        helpBlockcheckbox.classList.add("d-none");
    }
    else {
        if (helpBlockcheckbox.classList.contains("d-none")) {
            helpBlockcheckbox.classList.remove("d-none");
        }
        todook = false;
    }

    if(recaptcha) {
        helpBlockrecapt.classList.add("d-none");
    }
    else {
        if (helpBlockrecapt.classList.contains("d-none")) {
            helpBlockrecapt.classList.remove("d-none");
        }
        todook = false;
    }

    return todook;
}
function validaEmail(i) {
    let lowerCaseString = i.toLowerCase();
    // Divide el correo en nombre de usuario y dominio
    const [nombreUsuario, dominio] = lowerCaseString.split('@');

    // Verifica el formato del nombre de usuario
    const usuarioRegex = /^[a-zA-Z0-9_.-]+$/;
    const usuarioValido = usuarioRegex.test(nombreUsuario) &&
        !nombreUsuario.startsWith('.') &&
        !nombreUsuario.endsWith('.') &&
        !nombreUsuario.includes('..');

    // Verifica el formato del dominio
    const dominioRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
    const dominioValido = dominioRegex.test(dominio) &&
        dominio.split('.').length >= 2;

    // Devuelve true si ambos son válidos, o false en caso contrario
    return usuarioValido && dominioValido;
}

function validarTelefonoCompleto(numero) {
    const noSpacePhoneNumber = numero.replace(' ', '');
    numero = "+" + noSpacePhoneNumber;
    const regex = /^\+?[0-9]{1,3}[0-9]{7,12}$/; // Prefijo 0034, 7 a 12 dígitos
    return regex.test(numero);
}
