
export default function AvisoLegal() {

    return (
        <>
        <div className = "text-white presentation presentation-quienes pt-5">
            <div className = "infopoliticas">
                <div className = "cuadro-borde m-1 ms-4 me-4 mt-3 mb-1 p-3 pt-4">
                    <h1 className = "elegant-line-bottom text-center"> Aviso Legal </h1>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">INTRODUCCION:</h3>
                        <p>El presente documento tiene como finalidad el establecer y regular las normas de uso de este portal
                            web, entendiendo por éste todas las páginas y sus contenidos, propiedad de <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>, a las cuales se accede a través del dominio
                            <a href = "https://www.fpprime.es" className = "highlight-emails"> www.fpprime.es </a>. La utilización del portal web atribuye la condición de usuario del mismo e
                            implica la aceptación de todas las condiciones incluidas en el presente aviso Legal. El usuario se
                            compromete a leer atentamente el presente aviso Legal en cada una de las ocasiones en que se
                            proponga utilizar nuestro portal web, ya que éste y sus condiciones de uso recogidas en el presente
                            aviso Legal pueden sufrir modificaciones</p>
                        <p>En cumplimiento con el deber de información recogido en el artículo 10 de la Ley 34/2002, de 11 de
                            julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, a continuación, se
                            reflejan los siguientes datos:</p>
                        <ul>
                            <li className = "espaceli"><b> Razón social: </b> INSTITUTO FP-PRIME</li>
                            <li className = "espaceli"><b> CIF: </b> B94168754</li>
                            <li className = "espaceli"><b> Domicilio: </b> Rúa Santa Clara nº21, 36002 Pontevedra</li>
                            <li className = "espaceli"><b> Entrada por: </b> Plaza de la Castaña, 36002 Pontevedra</li>
                            <li className = "espaceli"><b> Teléfono: </b> <a href = "tel:+34666583232" className = "highlight-emails"> +34 666 583 232 </a></li>
                            <li className = "espaceli"><b> Teléfono: </b> <a href = "tel:+34690942555" className = "highlight-emails"> +34 690 942 555 </a></li>
                            <li className = "espaceli"><b> Correo electrónico: </b> <a href = "mailto:info@fpprime.es" className = "highlight-emails"> info@fpprime.es </a></li>
                            <li className = "espaceli"><b> Nombre del dominio: </b> <a href = "https://www.fpprime.es" className = "highlight-emails"> www.fpprime.es </a></li>
                        </ul>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">USUARIAS Y USUARIOS:</h3>
                        <p>El acceso y uso de este portal , atribuye la condición de USUARIA/O, que acepta, desde dicho acceso
                            y uso, las Condiciones Generales de Uso aquí reflejadas. Las citadas Condiciones serán de
                            aplicación independientemente de las Condiciones Generales de Contratación que en su caso
                            resulten de obligado cumplimiento.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">USO DEL PORTAL:</h3>
                        <p>Proporciona el acceso a diversas informaciones sobre nuestra entidad, apartados de contacto,
                            hiperenlaces a redes sociales, en adelante contenidos pertenecientes a <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>, o a sus licenciadores, a los que la usuaria o el usuario pueda
                            tener acceso. La usuaria o el usuario asume la responsabilidad del uso del portal. Dicha
                            responsabilidad se extiende al registro que fuese necesario para acceder a determinados servicios o
                            contenidos, o bien para posibilitar la respuesta en relación con sus consultas en los apartados de
                            contacto de la presente página web, etc. En dicho registro la usuaria o el usuario será responsable
                            de aportar información veraz y lícita.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">PROPIEDAD INTELECTUAL E INDUSTRIAL:</h3>
                        <p><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>, por sí o como cesionaria, es titular de
                            todos los derechos de propiedad intelectual e industrial de su página web, así como de los
                            elementos contenidos en la misma (a título enunciativo: imágenes, sonido, audio, vídeo, software o
                            textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales
                            usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.). Todos los
                            derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.2, párrafo segundo, de la Ley
                            de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la
                            comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los
                            contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio
                            técnico, sin la autorización de <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>. En
                            ningún momento, salvo manifestación expresa de lo contrario, el acceso, navegación o utilización
                            del sitio Web o de sus contenidos confiere a la usuaria o al usuario derecho alguno sobre signos
                            distintivos en él incluidos. La usuaria o el usuario se compromete a respetar los derechos de
                            Propiedad Intelectual e Industrial titularidad de <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>. En el caso de que la usuaria o el usuario envíe información de cualquier tipo a
                            <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> a través de cualquiera de los canales
                            habilitados para ello dentro de nuestro portal web, la usuaria o el usuario declara, garantiza y acepta
                            que tiene derecho a hacerlo con total libertad, que dicha información no infringe ningún derecho de
                            propiedad intelectual, industrial, o cualesquiera otros derechos de terceras personas, y que dicha
                            información no tiene carácter confidencial ni es perjudicial para terceras personas.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD:</h3>
                        <p><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> no se hace responsable, en ningún caso,
                            de los daños y perjuicios de cualquier naturaleza que se pudieran ocasionar, a título enunciativo:
                            errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o
                            programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas
                            tecnológicas necesarias para evitarlo. El contenido, información y consejos expresados en este
                            portal web deben entenderse como simplemente orientativos. <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> no responde de ninguna forma de la efectividad o exactitud de los
                            mismos, quedando exento de cualquier responsabilidad con los usuarios y usuarias que hagan uso
                            de ellos.</p>
                        <p>En este Sitio se pueden publicar contenidos y comentarios aportados por terceras personas.
                            <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> no responde de la veracidad y exactitud
                            de los mismos, quedando exenta de cualquier responsabilidad con los usuarios y con las usuarias
                            que hagan uso de ellos. <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> se reserva el
                            derecho de modificar el contenido del Sitio sin previo aviso y sin ningún tipo de limitación. Así
                            mismo, la empresa declina cualquier responsabilidad por los eventuales daños y perjuicios que
                            puedan ocasionarse por la falta de disponibilidad o continuidad de este sitio y de los servicios que
                            se ofrecen en él. Tampoco podemos garantizar la ausencia de virus ni de otros elementos en la Web
                            que puedan producir alteraciones en su sistema informático. <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> declina cualquier responsabilidad por los servicios e información que se
                            preste en otros sitios enlazados con este, ya que no controla ni ejerce ningún tipo de supervisión en
                            Sitios Webs de terceras personas.Aconsejamos a las usuarias y a los usuarios de los mismos que
                            actúen con prudencia y que consulten las eventuales condiciones legales que se expongan en
                            dichas Webs. Así mismo, los Usuarios/as que remitan cualquier tipo de información a se asegurarán
                            de que la misma sea veraz y de que no vulnere cualquier derecho de terceras personas ni la
                            legalidad vigente. Si Ud. cree que cualquier contenido o información de este Sitio vulnera un
                            derecho legítimo o la legalidad vigente le estaríamos muy agradecidos que se pusiera en contacto
                            con nosotros a través de los medios que considere oportunos bien a la dirección de correo
                            electrónico  <a href = "mailto:info@fpprime.es" className = "highlight-emails"> info@fpprime.es </a> o en el siguiente número de teléfono <a href = "tel:+34690942555" className = "highlight-emails"> 690 942 555 </a> para que
                            podamos tomar las medidas oportunas.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">MODIFICACIONES:</h3>
                        <p><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> se reserva el derecho de efectuar sin
                            previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o
                            añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que
                            estos aparezcan presentados o localizados en su portal.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">DESISTIMIENTO Y REEMBOLSO:</h3>
                        <p>Una vez iniciado el Activo de Aprendizaje o el Servicio adquirido el usuario pierde su derecho a Desistimiento.</p>
                        <p>Para ejercer su Derecho de Desistimiento el Usuario deberá comunicar su solicitud por email en la dirección <a href = "mailto:info@fpprime.es" className = "highlight-emails"> info@fpprime.es </a> indicando Nombre de usuario, Nombre y apellidos, Fecha de la compra y concepto e importe de la misma.</p>
                        <p>El usuario tiene derecho a desistir de la contratación realizada, en los términos previstos en la Ley de Consumidores y Usuarios durante un período de 14 días naturales a contar desde la formalización de la compra del Activo de Aprendizaje o Servicio mediante el pago del mismo.</p>
                        <p>En caso de desistimiento, <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> devolverá al usuario el importe total del curso por el mismo método por el que se hizo el pago, en un plazo máximo de 30 días naturales a partir de la fecha de comunicación por el usuario de dicho desistimiento.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">ENLACES:</h3>
                        <p>En el caso de que en nombre del dominio se pusieran en funcionamiento enlaces o hipervínculos
                            que recayeran en otros sitios de Internet <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>
                            no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> asumirá responsabilidad alguna por los
                            contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad
                            técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier
                            material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet.
                            Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.</p>
                        <p>La usuaria o el usuario reconoce y acepta que <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> no será responsable de las pérdidas o daños en que la usuaria o el usuario pueda
                            incurrir a consecuencia de la disponibilidad de los mencionados sitios web o recursos externos, o a
                            consecuencia de la credibilidad que otorgue a la exhaustividad, precisión o existencia de cualquier
                            tipo de publicidad, productos u otros materiales ofrecidos a través de dicho sitio web u otros
                            recursos.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">DERECHO DE EXCLUSIÓN:</h3>
                        <p><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> se reserva el derecho a denegar o retirar
                            el acceso a portal o a los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de
                            terceras personas, a aquellos usuarios y a aquellas usuarias que incumplan las presentes
                            Condiciones Generales de Uso.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">GENERALIDADES:</h3>
                        <p><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> perseguirá el incumplimiento de las
                            presentes condiciones, así como cualquier utilización indebida de su portal ejerciendo todas las
                            acciones civiles y penales que le puedan corresponder en derecho.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">LEGISLACIÓN APLICABLE Y JURISDICCIÓN:</h3>
                        <p>La relación entre <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> y la usuaria o el usuario
                            se regirá por la normativa española vigente. Y siempre que la normativa vigente al efecto establezca
                            la posibilidad para las partes la sumisión a un fuero determinado cualquier controversia entre la
                            persona responsable del sitio web y la usuaria o el usuario se someterá a los Juzgados y tribunales
                            de la ciudad de Pontevedra.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">PLATAFORMA EUROPEA DE RESOLUCIÓN DE CONFLICTOS EN LÍNEA:</h3>
                        <p>Le informamos que ante cualquier problema que pueda derivarse del servicio contratado o producto
                            vendido, puede utilizar la plataforma europea de resolución de disputas online, esta es:
                        </p>
                        <a className = "espacea highlight-emails" href = "https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=ES" target = "_blank" rel = "noreferrer" >https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=ES</a>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
