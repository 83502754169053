export default function ConocenosInfo(props) {

    return (
        <section className = "quiensoy" id = "quiensoy">
            <div className = "text-white text-center presentation presentation-quienes">
                <div className = "conocenosinfo">
                    <div className = "cuadro-borde m-1 ms-4 me-4 mt-3 mb-1 p-4 animated-img">
                        <h4 className = "elegant-line-bottom"> ¿Quiénes somos? </h4>
                        <p>
                            Bienvenidos al Instituto FP PRIME,
                        </p>
                        <p>
                            Somos un equipo con más de 20 años de experiencia en el apasionante mundo de la formación.
                            Durante estas dos décadas, hemos adquirido un profundo conocimiento del sector y ahora
                            estamos encantados de materializar toda esa experiencia en nuestro instituto de formación
                            profesional.
                        </p>
                        <p>
                            En FP PRIME, nuestra misión es clara: preparar a nuestros estudiantes para un futuro exitoso.
                            Creemos en la formación rigurosa, específica y de calidad, y nos esforzamos por brindar a
                            nuestros alumnos las habilidades y el conocimiento necesarios para sobresalir en un mundo en
                            constante evolución. Estamos comprometidos a formar a los mejores profesionales, aquellos
                            que destacarán en una sociedad que busca especialización y excelencia.
                        </p>
                        <p>
                            Nuestro proyecto es nuevo, pero nuestra pasión por la educación y nuestro compromiso con el
                            éxito de nuestros estudiantes son duraderos. Estamos ansiosos por ser parte de tu viaje
                            educativo y trabajar juntos para que alcances tus metas y contribuyas de manera notable a la
                            sociedad.
                        </p>
                        <p>
                            En FP PRIME, tu futuro comienza hoy.
                        </p>
                    </div>
                </div>
            </div>
       </section>
    );
}