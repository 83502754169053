import logo from '../../opositalogo_menu.avif';

export default function Header(props) {
    return (
        <div className={props.fondo+" text-white p-2 ps-5 pe-5 text-center"}>
            <div className="row">
                <div className="col-md-10 text-md-start">
                    <a href="/"><img src={logo} alt="Logo Oposita Formación" title="Logo Oposita Formación" id="logo" className="pt-2 pb-2" />
                    </a>
                </div>
                <div className="col-md-2 text-md-end">
                    <a href="/" className="btn btn-primary mt-3">Volver al inicio
                    </a>
                    {props.sesion ?
                    <a href="/" className="btn btn-primary ms-3 mt-3">Cerrar sesión
                    </a> : ""
                    }
                </div>
            </div>
        </div>
    )
}
