const Convocatorias = (props) =>  {
    const { convocatorias } = props;
    return (
        <>
            {convocatorias.map((fecha, index) => (
                <p key={index}><input key={fecha.id} type="radio" name="convocatoria" defaultChecked={index===0 ? true : false}></input> Del {new Date(fecha.fechainicio).toLocaleDateString()} al {new Date(fecha.fechafin).toLocaleDateString()}</p>
                ))
            }
        </>
    )
}
export default Convocatorias;