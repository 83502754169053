import { useState } from "react";
import config from './../config/apiConfig';
import { useEffect } from "react";

import Header from './curso/Header.js';
import Body from './curso/Body.js';
import {useParams} from "react-router-dom";

export default function Curso() {

    let param = useParams();
    const [error, setError]                       = useState(null);
    const [isLoaded, setIsLoaded]                 = useState(false);
    const [oposicion, setOposicion] = useState([]);

    useEffect(() => {
        fetch(config.API_BASE_URL+ '/oposicion.php?tipo=vista&codigoempresa=8942LP29432PQ44&trcce_id='+param.id)
            .then(response => response.json())
            .then((result) => {
                setIsLoaded(true);
                setOposicion(result);

            })
            .catch(error => {
                setIsLoaded(true);
                setError(error);
            });
    }, [param.id])

    return (
        <div>
            <Header error = { error } categorias = { oposicion.oposiciones_categoria } comunidades = { oposicion.comunidades } isLoaded = { isLoaded } />
            <Body error = { error } oposicion = { oposicion.info } convocatorias = { oposicion.convocatorias } isLoaded = { isLoaded } />
        </div>
    );

}
