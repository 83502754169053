
export default function PoliticaPrivacidad() {

    return (
        <div className = "text-white presentation presentation-quienes pt-5">
            <div className = "infopoliticas">
                <div className = "cuadro-borde m-1 ms-4 me-4 mt-3 mb-1 p-3 pt-4">
                    <h1 className = "elegant-line-bottom text-center"> Política de Privacidad </h1>
                    <div className = "row mb-4 ms-2 me-2">
                        <h4 className = "mb-3 text-center" style = {{ color: "#3c3c3c" }}>Información adicional sobre nuestra política de protección de datos:</h4>
                        <p>Cuestiones comunes para todas las actividades de tratamiento:</p>
                        <h3 className = "mb-2"><b>¿Quién se responsabiliza del tratamiento de tus datos?</b></h3>
                        <div className = "table-responsive-sm p-0">
                            <table className = "table table-hover text-white my-space">
                                <tbody>
                                    <tr>
                                        <th>Responsable de los datos</th>
                                        <td className = "my-space">INSTITUTO FP-PRIME</td>
                                    </tr>
                                    <tr>
                                        <th>Número de documento</th>
                                        <td className = "my-space">B94168754</td>
                                    </tr>
                                    <tr>
                                        <th>Dirección fiscal</th>
                                        <td className = "my-space">Rúa Santa Clara nº21, 36002 Pontevedra</td>
                                    </tr>
                                    <tr>
                                        <th>Entrada por</th>
                                        <td className = "my-space">Plaza de la Castaña, 36002 Pontevedra</td>
                                    </tr>
                                    <tr>
                                        <th>Teléfono</th>
                                        <td> <a href = "tel:+34690942555" className = "text-decoration-none"> +34 690 942 555 </a></td>
                                    </tr>
                                    <tr>
                                        <th>Correo electrónico</th>
                                        <td className = "my-space"><a href = "mailto:info@fpprime.es" className = "text-decoration-none"> info@fpprime.es </a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">¿Cuáles son tus derechos cuando nos facilitas tus datos?</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos
                            personales que les conciernen o no. En este sentido, tienes derecho a solicitar:</p>

                        <p style = {{ marginLeft: "4%", width: "95%" }}><b style={{ color: "#d9ad26"}}>Acceso: </b>La persona que nos facilita sus datos tendrá derecho a obtener del responsable
                            del tratamiento confirmación de si se están tratando o no los datos que le concierne, así
                            como información detallada acerca de determinados aspectos del tratamiento que se esté
                            llevando a cabo.</p>
                        <p style = {{ marginLeft: "4%", width: "95%" }}><b style={{ color: "#d9ad26"}}>Rectificación: </b>La persona que nos facilita sus datos tendrá derecho a obtener la
                            rectificación de los datos personales inexactos que le conciernen o a que se completen
                            aquellos que fueran incompletos.</p>
                        <p style = {{ marginLeft: "4%", width: "95%" }}><b style={{ color: "#d9ad26"}}>Supresión: </b>La persona que nos facilita sus datos tendrá derecho a solicitar la supresión de
                            sus datos personales; en todo caso la supresión estará sujeta a los límites establecidos
                            en la norma reguladora.</p>
                        <p style = {{ marginLeft: "4%", width: "95%" }}><b style={{ color: "#d9ad26"}}>Limitación de su tratamiento: </b>persona que nos facilita sus datos tendrá derecho a
                            solicitar la limitación del tratamiento de sus datos de carácter personal.</p>
                        <p style = {{ marginLeft: "4%", width: "95%" }}><b style={{ color: "#d9ad26"}}>Oposición al tratamiento: </b>En determinadas circunstancias, y por motivos relacionados con
                            su situación particular, las personas que nos facilitan sus datos podrán oponerse al
                            tratamiento de los mismos. La entidad dejará de tratarlos, salvo por motivos legítimos,
                            imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>
                        <p style = {{ marginLeft: "4%", width: "95%" }}><b style={{ color: "#d9ad26"}}>Derecho a la portabilidad de sus datos: </b>La persona que nos facilita sus datos tendrá
                            derecho a recibir los datos personales que le incumban, aquellos que haya facilitado a la
                            persona responsable del tratamiento, en un formato estructurado, de uso común y de
                            lectura mecánica, así como a transmitirlos a cualquiera otra persona que pueda ser
                            responsable del tratamiento.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">USO DEL PORTAL:</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>Proporciona el acceso a diversas informaciones sobre nuestra entidad, apartados de contacto,
                            hiperenlaces a redes sociales, en adelante contenidos pertenecientes a <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> , o a sus licenciadores, a los que la usuaria o el usuario pueda
                            tener acceso. La usuaria o el usuario asume la responsabilidad del uso del portal. Dicha
                            responsabilidad se extiende al registro que fuese necesario para acceder a determinados servicios o
                            contenidos, o bien para posibilitar la respuesta en relación con sus consultas en los apartados de
                            contacto de la presente página web, etc. En dicho registro la usuaria o el usuario será responsable
                            de aportar información veraz y lícita.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">PROPIEDAD INTELECTUAL E INDUSTRIAL:</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>, por sí o como cesionaria, es titular de
                            todos los derechos de propiedad intelectual e industrial de su página web, así como de los
                            elementos contenidos en la misma (a título enunciativo: imágenes, sonido, audio, vídeo, software o
                            textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales
                            usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.). Todos los
                            derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.2, párrafo segundo, de la Ley
                            de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la
                            comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los
                            contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio
                            técnico, sin la autorización de <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>. En ningún momento, salvo manifestación expresa de lo contrario, el acceso, navegación o utilización
                            del sitio Web o de sus contenidos confiere a la usuaria o al usuario derecho alguno sobre signos
                            distintivos en él incluidos. La usuaria o el usuario se compromete a respetar los derechos de
                            Propiedad Intelectual e Industrial titularidad de <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>. En el caso de que la usuaria o el usuario envíe información de cualquier tipo
                            a <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> a través de cualquiera de los canales habilitados para ello dentro de nuestro portal web, la usuaria o el usuario declara, garantiza y acepta
                            que tiene derecho a hacerlo con total libertad, que dicha información no infringe ningún derecho de
                            propiedad intelectual, industrial, o cualesquiera otros derechos de terceras personas, y que dicha
                            información no tiene carácter confidencial ni es perjudicial para terceras personas.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD:</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> no se hace responsable, en ningún caso,
                            de los daños y perjuicios de cualquier naturaleza que se pudieran ocasionar, a título enunciativo:
                            errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o
                            programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas
                            tecnológicas necesarias para evitarlo. El contenido, información y consejos expresados en este
                            portal web deben entenderse como simplemente orientativos. <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> no responde de ninguna forma de la efectividad o exactitud de los
                            mismos, quedando exento de cualquier responsabilidad con los usuarios y usuarias que hagan uso
                            de ellos.</p>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>En este Sitio se pueden publicar contenidos y comentarios aportados por terceras personas.
                            <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> no responde de la veracidad y exactitud
                            de los mismos, quedando exenta de cualquier responsabilidad con los usuarios y con las usuarias
                            que hagan uso de ellos. <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> se reserva el
                            derecho de modificar el contenido del Sitio sin previo aviso y sin ningún tipo de limitación. Así
                            mismo, la empresa declina cualquier responsabilidad por los eventuales daños y perjuicios que
                            puedan ocasionarse por la falta de disponibilidad o continuidad de este sitio y de los servicios que
                            se ofrecen en él. Tampoco podemos garantizar la ausencia de virus ni de otros elementos en la Web
                            que puedan producir alteraciones en su sistema informático. <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> declina cualquier responsabilidad por los servicios e información que se
                            preste en otros sitios enlazados con este, ya que no controla ni ejerce ningún tipo de supervisión en
                            Sitios Webs de terceras personas.Aconsejamos a las usuarias y a los usuarios de los mismos que
                            actúen con prudencia y que consulten las eventuales condiciones legales que se expongan en
                            dichas Webs. Así mismo, los Usuarios/as que remitan cualquier tipo de información a se asegurarán
                            de que la misma sea veraz y de que no vulnere cualquier derecho de terceras personas ni la
                            legalidad vigente. Si Ud. cree que cualquier contenido o información de este Sitio vulnera un
                            derecho legítimo o la legalidad vigente le estaríamos muy agradecidos que se pusiera en contacto
                            con nosotros a través de los medios que considere oportunos bien a la dirección de correo
                            electrónico <a href = "mailto:info@fpprime.es" className = "highlight-emails"> info@fpprime.es </a> o en los siguientes números de teléfono <a href = "tel:+34666583232" className = "highlight-emails"> 666 583 232</a> <a href = "tel:+34690942555" className = "highlight-emails"> 690 942 555 </a> para que
                            podamos tomar las medidas oportunas.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">MODIFICACIONES:</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> se reserva el derecho de efectuar sin
                            previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o
                            añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que
                            estos aparezcan presentados o localizados en su portal.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">DESISTIMIENTO Y REEMBOLSO:</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>Una vez iniciado el Activo de Aprendizaje o el Servicio adquirido el usuario pierde su derecho a Desistimiento.</p>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>Para ejercer su Derecho de Desistimiento el Usuario deberá comunicar su solicitud por email en la dirección <a href = "mailto:info@fpprime.es" className = "highlight-emails"> info@fpprime.es </a> indicando Nombre de usuario, Nombre y apellidos, Fecha de la compra y concepto e importe de la misma.</p>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>El usuario tiene derecho a desistir de la contratación realizada, en los términos previstos en la Ley de Consumidores y Usuarios durante un período de 14 días naturales a contar desde la formalización de la compra del Activo de Aprendizaje o Servicio mediante el pago del mismo.</p>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>En caso de desistimiento, <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> devolverá al usuario el importe total del curso por el mismo método por el que se hizo el pago, en un plazo máximo de 30 días naturales a partir de la fecha de comunicación por el usuario de dicho desistimiento.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">ENLACES:</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>En el caso de que en nombre del dominio se pusieran en funcionamiento enlaces o hipervínculos
                            que recayeran en otros sitios de Internet <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span>
                             no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> asumirá responsabilidad alguna por los
                            contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad
                            técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier
                            material o información contenida en ninguno de dichos hipervínculos u otros sitios de Internet.
                            Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.</p>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>La usuaria o el usuario reconoce y acepta que <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> no será responsable de las pérdidas o daños en que la usuaria o el usuario pueda
                            incurrir a consecuencia de la disponibilidad de los mencionados sitios web o recursos externos, o a
                            consecuencia de la credibilidad que otorgue a la exhaustividad, precisión o existencia de cualquier
                            tipo de publicidad, productos u otros materiales ofrecidos a través de dicho sitio web u otros
                            recursos.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">DERECHO DE EXCLUSIÓN:</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> se reserva el derecho a denegar o retirar
                            el acceso a portal o a los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de
                            terceras personas, a aquellos usuarios y a aquellas usuarias que incumplan las presentes
                            Condiciones Generales de Uso.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                     <h3 className = "mb-2">GENERALIDADES:</h3>
                        <p style = {{ marginLeft: "2%" }}><span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> perseguirá el incumplimiento de las
                            presentes condiciones, así como cualquier utilización indebida de su portal ejerciendo todas las
                            acciones civiles y penales que le puedan corresponder en derecho.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">LEGISLACIÓN APLICABLE Y JURISDICCIÓN:</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>La relación entre <span className = "dark-fpprime-name">INSTITUTO FP-PRIME</span> y la usuaria o el usuario
                            se regirá por la normativa española vigente. Y siempre que la normativa vigente al efecto establezca
                            la posibilidad para las partes la sumisión a un fuero determinado cualquier controversia entre la
                            persona responsable del sitio web y la usuaria o el usuario se someterá a los Juzgados y tribunales
                            de la ciudad de Pontevedra.</p>
                    </div>
                    <div className = "row mb-4 ms-2 me-2">
                        <h3 className = "mb-2">PLATAFORMA EUROPEA DE RESOLUCIÓN DE CONFLICTOS EN LÍNEA:</h3>
                        <p style = {{ marginLeft: "2%", width: "97%" }}>Le informamos que ante cualquier problema que pueda derivarse del servicio contratado o producto
                            vendido, puede utilizar la plataforma europea de resolución de disputas online, esta es:
                        </p>
                        <a className = "espacea highlight-emails" href = "https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=ES" target = "_blank" rel="noreferrer">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&lng=ES.</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
