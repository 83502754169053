import React, { useState } from 'react';
import config from "../../config/apiConfig";
const Login = ({ handleShowType,handleShowAlert, handleUser }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        // Datos del formulario
        const formData = {
            username: username,
            password: password,
            codigoempresa: "8942LP29432PQ44"
        };

        // Realizar la solicitud HTTP POST
        fetch( config.API_BASE_URL+'/login.php', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => response.json())
            .then(data => {
                // Manejar la respuesta de la API
                console.log(data); // Puedes mostrar la respuesta en la consola o tomar acciones basadas en ella
                if(data.response.success===true)
                {
                    handleShowType("paymethods");
                    handleShowAlert("success",data.response.title,data.response.msg);
                    handleUser(data.user);
                }
                else
                {
                    handleShowAlert("danger",data.response.title,data.response.msg);
                }
            })
            .catch(error => {
                // Manejar errores de la solicitud
                console.error(error);
            });
    };

    return (
        <div className="p-5 bg-primary text-white">
            <h4 className="pb-3">Inicia sesión para completar el pedido</h4>
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="username" className="form-label">Email</label>
                <input type="email"
                       className="form-control"
                       value={username}
                       onChange={(e) => setUsername(e.target.value)}
                       id="username"
                       placeholder="name@example.com" />
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Contraseña</label>
                <input type="password"
                       className="form-control"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       name="contrasena"
                       id="password"/>
            </div>
            <div className="mb-3">
                <button className="btn btn-primary me-4 mb-3" type="submit">Iniciar sesión</button>
                <button className="btn btn-warning mb-3" onClick={() => handleShowType("register")}>Todavía no estoy registrado</button>
            </div>
            </form>
            <p><a href="#" className="link-light">He olvidado mi contraseña</a></p>
        </div>
    );
}

export default Login;