import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import React from "react";

export default function FiltrosHeader(props) {
        console.log(props)
    if (props.error) {
        return <div>Error: {props.error.message}</div>;
    } else if (!props.isLoaded) {
        return <Spinner animation="border" variant="light" />;
    } else {
        console.log(props)
    return (
        <div className="col-md-3 col-lg-3 d-flex  text-md-end">
        <Dropdown>
            <Dropdown.Toggle className="transparentbar  text-white border-0 m-3"  id="">
                Oposición
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {props.comunidades.map(comunidad => (
                    <Dropdown.Item href={comunidad.trcce_id}   value={comunidad.id}> <div className="content" dangerouslySetInnerHTML={{__html:comunidad.nombre}} ></div></Dropdown.Item>
                ))}
            </Dropdown.Menu>

        </Dropdown>
        <Dropdown>
            <Dropdown.Toggle className="text-white  border-0 m-3 transparentbar" id="">
                Organismo
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {props.categorias.map(categoria => (
                    <Dropdown.Item href={categoria.trcce_id} value={categoria.id}>{categoria.nombre}</Dropdown.Item>
                ))}
            </Dropdown.Menu>

        </Dropdown>
        </div>
    );
    }
}
