
import React from "react";
import ConocenosInfo from "./index/body/ConocenosInfo";
import DondeEstamosView from "./index/partes/DondeEstamosView";
import CarrouselPrincipal from "./index/body/CarrouselPrincipal";
import RamasView from "./index/partes/RamasView";
import TrabajaConFPprime from "./index/partes/TrabajaConFPprime";
import MasInfoCursoView from "./index/InfoCiclos/MasInfoCursoView";

export default function Home() {
    const aux = 43;
    return (
       <div>
           <CarrouselPrincipal/>
           <RamasView />
           {/*<ConoceFPprimeView />*/}
           <MasInfoCursoView />
           <TrabajaConFPprime />
           <ConocenosInfo />
           {/*<DondeEstamosView id = { null } />*/}
           <br />
           <DondeEstamosView id = { aux } />
        </div>
    );
}