import { useState } from "react";
import Convocatorias from '././Convocatorias';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../general/footer';
import Button from "react-bootstrap/Button";

//TODO comprobar el button component en vez de el button html


export default function Body(props) {

        const [show, setShow]           = useState(true);
        // const [rutapedidos, setRutaPedidos] = useState([]);
     //
     //
     //    const handleConvocatoria = (evento) => {
     //    handleRutaPedidos(props.oposicion_id,evento.target.value);
     //    };
     //
     //    const handleRutaPedidos = (oposicion_id,convocatoria_id) => {
     //    setRutaPedidos("/pedidos/"+oposicion_id+"/"+convocatoria_id);
     //    };
     //        console.log(rutapedidos)
     //
     //    useEffect(() => {
     //        fetch(config.API_BASE_URL+ '/oposicion.php?tipo=vista&codigoempresa=8942LP29432PQ44&trcce_id='+props.idOposicion)
     //            .then(response => response.json())
     //            .then((result) => {
     //                setIsLoaded(true);
     //                setOposicion(result);
     //                handleRutaPedidos(result.info.id,result.convocatorias[0].id);
     //            })
     //            .catch(error => {
     //                setIsLoaded(true);
     //                setError(error);
     //            });
     //    }, [props.oposicion_id])

        if (props.error) {
            return (
                <ToastContainer className="p-3" position="middle-center">
                    <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide>
                        <Toast.Header>
                            Error al cargar el módulo
                        </Toast.Header>
                    </Toast>
                </ToastContainer>
            )
        } else if (!props.isLoaded) {
            return (
                <ToastContainer className="p-3 position-fixed" position="middle-center" >
                    <Toast onClose={() => setShow(false)} show={show} delay={1000} autohide bg="secondary">
                        <Toast.Header>
                            <Spinner animation="border" variant="dark" className="me-3"/>
                            <strong className="me-auto">Cargando oposición</strong>
                        </Toast.Header>
                    </Toast>
                </ToastContainer>
            );
        } else {
                console.log(props)
            return (
        <div>
            <div className="row w-100 p-5">
               <div className="col-md-7">
                   <div className="row">
                        <h2 className="text-secondary pb-2 mb-3 col-9">{props.oposicion.nombrecursoacreditado}</h2>
                       <Button href={"/pedidos/"+props.oposicion.id+"/"+props.convocatorias[0].id} rel="noreferrer" className="btn-secondary p-3 col-3"><b>Ver opciones de compra</b></Button>
                   </div>
                   <hr />
                   <p className="mt-5"><b>CURSO DE PREPARACIÓN:</b></p>
                   <div className="pt-4 pb-3 ps-4 pe-4 border bg-white">
                       <p><b className="text-secondary"><i className="bi bi-alarm-fill pe-3"></i> DURACIÓN:</b> {props.oposicion.mesesduracion} meses
                       </p>
                       <p><b className="text-secondary"><i className="bi bi-calendar2-week-fill pe-3"></i> PRÓXIMA
                           CONVOCATORIA:</b>
                           <Convocatorias
                               convocatorias = {props.convocatorias}
                           />
                       </p>


                   </div>
                   <p className="mt-5"><b>CARACTERÍSTICAS OPOSICIÓN:</b></p>
                   <div className="p-3 ps-4 pe-4 border bg-white">
                       <ui>
                           <p><b className="text-secondary"> <i className="bi bi-people-fill pe-3"></i> NUMERO DE
                               PLAZAS:</b> {props.oposicion.plazas}</p>
                           <p><b className="text-secondary"> <i className="bi bi-pencil-fill pe-3"></i> PRESENTACIÓN DE
                               INSTANCIAS:</b> {props.oposicion.instancias}</p>
                           <p><b className="text-secondary"> <i className="bi bi-person-square pe-3"></i> Oferta de
                               Empleo:</b> <a
                               href={props.oposicion.empleo}
                               target="_blank" rel="noreferrer" >30-12-2021 | BOJA | Más información</a></p>
                           <b className="text-secondary"><i className="bi bi-calendar-week-fill pe-3"></i> Convocatoria:</b>
                           <a href={props.oposicion.convocatorias}
                              target="_blank" rel="noreferrer" >30-12-2022 | BOJA | Más información</a><p></p>

                       </ui>
                   </div>
                   <div className="mt-5">
                       <Accordion  flush>
                           <Accordion.Item   eventKey="0">
                               <Accordion.Header>Datos Generales</Accordion.Header>
                               <Accordion.Body>
                                   <div className="content" dangerouslySetInnerHTML={{__html:props.oposicion.datosgenerales}}></div>
                               </Accordion.Body>
                           </Accordion.Item>
                           <Accordion.Item   eventKey="1">
                               <Accordion.Header>Ejercicios</Accordion.Header>
                               <Accordion.Body>
                                   <div className="content" dangerouslySetInnerHTML={{__html:props.oposicion.ejercicios}}></div>
                               </Accordion.Body>
                           </Accordion.Item>
                           <Accordion.Item eventKey="2">
                               <Accordion.Header>Temario</Accordion.Header>
                               <Accordion.Body>
                                   <div className="content" dangerouslySetInnerHTML={{__html:props.oposicion.temario}}></div>
                               </Accordion.Body>
                           </Accordion.Item>
                           <Accordion.Item eventKey="3">
                               <Accordion.Header>Metodología</Accordion.Header>
                               <Accordion.Body>
                                   <div className="content" dangerouslySetInnerHTML={{__html: props.oposicion.metodologia}}></div>
                               </Accordion.Body>
                           </Accordion.Item>
                       </Accordion>

                   </div>
               </div>


                {/*--------------------------------------------------------------------------------------------*/}



                <div className="col-md-5  h-100">
                    <img src={props.oposicion.img} className="col-2 pb-3 w-100"
                         width="60" alt="libros"/>

                    {/* esto es el cuadradito*/}
                    <div className="p-3 ps-4 pe-4  border bg-white">
                        <div className="row">
                            <img src="https://opositaformacion.com/img/libro_fisico_y_digital.png" className="col-2"
                                 width="60" alt="libros"/>
                                <p className="col-10"> Disponemos de todo el temario en varios formatos para que pueda
                                    estudiar cómo, cuándo y dónde quiera.</p>
                        </div>

                        <div className="row">
                            <img src="https://opositaformacion.com/img/Icono_juego_de_oposiciones.png "
                                 className="col-2" width="60" alt=" auriculares"/>
                                <p className="col-10"> Le ayudamos a hacer una planificación de repaso eficaz durante
                                    los meses previos al examen oficial</p>
                        </div>

                        <div className="row">
                            <img src="https://opositaformacion.com/img/videoconferencia.png " className="col-2"
                                 width="60" alt="pantalla con pinceles"/>
                                <p className="col-10"> Los docentes realizan videoconferencias sobre temas relacionados
                                    con el temario</p>
                        </div>

                        <div className="row">
                            <img src="https://opositaformacion.com/img/simulacro_de_examen.png" className="col-2"
                                 width="60" alt="lista de tareas"/>
                                <p className="col-10"> Nuestra plataforma dispone de múltiples simulacros de examen y
                                    actividades interactivas</p>
                        </div>
                    </div>

                </div>
            </div>
        <Footer/>
        </div>

        )
    }
}