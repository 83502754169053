import React from "react";
import empresas from "../InfoCiclos/empresas";

const UbiEmpresa = ({ nombre, direccion, url, ubiEmpresa, otraDir }) => (
    <>
        <div className = "col-lg-2 col-sm-4 me-4 container-text-aligned">
            <h1 className = "text-yellow"> {nombre} </h1>
            <span> {direccion} </span>
            {otraDir && (
                <span><u>Entrada por:</u> { otraDir }</span>
            )}
            <span><a href = {"mailto:"+ubiEmpresa.mailEmpresa} className = "highlight-emails txt-bold">{ubiEmpresa.mailEmpresa}</a></span>
            <span>{ubiEmpresa.horarioEmpresa} <a href = {"tel:+34"+ubiEmpresa.tel2callEmpresa} className = "highlight-emails txt-bold">{ubiEmpresa.telfEmpresa}</a></span>
            {ubiEmpresa.telfSecundario && (
                <span><a href = {"tel:+34"+ubiEmpresa.tel2callSecundario} className = "highlight-emails txt-bold">{ubiEmpresa.telfSecundario}</a></span>
            )}

        </div>
        <div className = "col-lg-2 col-sm-4 text-center">
            <div className = "text-center dondeestamosimgcontainer" id = "img-info-container">
                <img src = {url} alt = {nombre} className = "img-fluid-where" />
            </div>
        </div>
    </>

);

export default function DondeEstamosView ({id}) {

    let datosEmpresa;
    if(id !== null) {
        datosEmpresa = empresas.find(empr => empr.idEmpresa === parseInt(id, 10));
    }
    else {
        datosEmpresa = empresas;
    }

    const chunkEmpresas = (arr, chunkSize) => {
        const result = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            result.push(arr.slice(i, i + chunkSize));
        }
        return result;
    };

    const renderEmpresasRows = () => {

        if(id === null) {
            const ubiEmpresasArray = Object.values(datosEmpresa).flat();
            const chunkedEmpresas = chunkEmpresas(ubiEmpresasArray, 2);

            return chunkedEmpresas.map((chunk, groupIndex) => (
                <div className = "row mt-xxl-5 me-0 ms-0 justify-content-center text-start" key = {groupIndex}>
                    {chunk.map((ubiEmpresa, index, array) => (
                        <React.Fragment key = {ubiEmpresa.id}>
                            <UbiEmpresa
                                key = {ubiEmpresa.id}
                                id = {ubiEmpresa.id}
                                idEmpresa = {ubiEmpresa.idEmpresa}
                                nombre = {ubiEmpresa.nombreEmpresa}
                                ubicacion = {ubiEmpresa.ubicacionEmpresa}
                                direccion = {ubiEmpresa.direccionEmpresa}
                                direccionDel = {ubiEmpresa.direccionDelegacion}
                                ubiEmpresa = {ubiEmpresa}
                                otraDir = {ubiEmpresa.idEmpresa === 43 ? "Plaza de la Castaña, 36002 Pontevedra" : null}
                                url = {ubiEmpresa.url}
                            />
                            {index === 0 && array.length === 2 && (
                                <div className = "col-sm-1" ></div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            ));
        }
        else{
            return (
                <>
                    <br />
                    <div className = "row mt-lg-2 me-0 ms-0 justify-content-center text-start" key = {id}>
                        <UbiEmpresa
                            key = {datosEmpresa.id}
                            id = {datosEmpresa.id}
                            idEmpresa = {datosEmpresa.idEmpresa}
                            nombre = {datosEmpresa.nombreEmpresa}
                            ubicacion = {datosEmpresa.ubicacionEmpresa}
                            direccion = {datosEmpresa.direccionEmpresa}
                            direccionDel = {datosEmpresa.direccionDelegacion}
                            ubiEmpresa = {datosEmpresa}
                            otraDir = {datosEmpresa.idEmpresa === 43 ? "Rúa da Cruz Vermella 10 (bajo), esq. Praza da Castaña, 36002 Pontevedra" : null}
                            url = {datosEmpresa.url}
                        />
                    </div>
                </>
            );
        }
    };

    return (
        <div className = "colaboradores-container text-center">
            <h1 > Dónde encontrarnos: </h1>
            <div className = "bottom-line center" ></div>
            {renderEmpresasRows()}
        </div>
    );
}
