import Header from './general/Header.js';
import Noticia from './noticias/body/Noticia.js';
import NoticiasLista from './noticias/body/NoticiasLista.js';
import { useParams } from 'react-router-dom';
export default function Noticias() {
  
  let param = useParams();

  return (
    <>
    <Header fondo="bg-fpprime-gradient" />
      <div className="row pt-5 pb-5 gx-3 gy-3 ps-2 pe-2 me-0 ms-0" id="content">
        <div className="col-md-9">
            <div className="ms-md-5 border bg-white p-5">
              <Noticia noticia_id={param.id}  />
            </div>
        </div>
        <div className="col-md-3 overflow-auto d-inline-block pe-md-5">
          <NoticiasLista noticia_id={param.id}  />
        </div>
      </div>
    </>
  );
}