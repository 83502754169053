import React from "react";

export default function Loader () {
    return (
        <div className="d-lg-block solicitainfo openmodal" id="myloader">
            <section className="container-form bloque-form-homes">
                <div className="showmodal">
                    <div className="overlay"></div>
                    <div className="loader">
                        <div className="circle white"></div>
                        <div className="circle purple"></div>
                        <div className="circle pink"></div>
                        <div className="circle greenyellow"></div>
                    </div>
                </div>
            </section>
        </div>
    );
}