import React from "react";

export default function RamasView () {

    return (
        <div>
            <div className = "me-lg-5 ms-lg-5">
                <div className = "row mt-lg-5 me-0 ms-0 justify-content-center content-center">
                    <h1>Encuentra tu FP</h1>
                    <span >Disponemos de Ciclos Formativos de Grado Medio y de Grado Superior en diversos ámbitos, centrándonos en los ámbitos de:</span>
                </div>
                <div className = "row mt-lg-3 me-0 ms-0 justify-content-center content-center">
                    <div className="col-sm-4 justify-content-center container-text-aligned">
                        <a href="/cicloscategoria/3665/Sanidad" className="text-decoration-none">
                            <div className="rounded-circle cajatitulo m-3 shadow-lg pt-5 pb-5 text-center text-yellow">
                                <div className="my-icon">
                                    <i className="fa-solid fa-heart-pulse"></i>
                                </div>
                                <br />
                                <h4 className='text-dark-yellow'>Sanidad</h4>
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-4 justify-content-center container-text-aligned">
                        <a href="/cicloscategoria/3666/Administración%20y%20gestión" className="text-decoration-none">
                            <div className="rounded-circle cajatitulo m-3 pt-5 pb-5 text-center text-yellow">
                                <div className="my-icon">
                                    <i className="fa-solid fa-suitcase"></i>
                                </div>
                                <br />
                                <h4 className='text-dark-yellow'>Administración y Gestión</h4>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}