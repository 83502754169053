import { useEffect } from "react";
import config from './../../../config/apiConfig';
import { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import TagsOposicion from "../../index/body/TagsOposicion";
import Col from "react-bootstrap/Col";
import formate_img from './../../../img/general/formate.jpg';

export default function Noticia(props) {
    const [error, setError]         = useState(null);
    const [isLoaded, setIsLoaded]   = useState(false);
    const [noticia, setNoticia] = useState([]);

    useEffect(() => {
        fetch(config.API_BASE_URL+ '/noticias.php?codigoempresa=8942LP29432PQ44&tipo=noticia&id='+props.noticia_id)
        .then(response => response.json())
        .then((result) => {
            setIsLoaded(true);
            setNoticia(result);
        })
        .catch(error => {
            setIsLoaded(true);
            setError(error);
        });
    }, [props.noticia_id])

    if (error) {
        return (
            <div>Error en la carga</div>
        )
    } else if (!isLoaded) {
        return <div className="m-auto text-center mt-5"><Spinner animation="grow" variant="dark" /></div>;
    } else {

        let fechaPublicacion = new Date(noticia.fechapublicacion); // fecha inicial
        let imagenTag = noticia.tag_id ? noticia.tag_imagen.imagenes.webp : false;
        console.log(noticia.tag_oposiciones[0].precio)
        return (
            <>
                <h2 className="mb-3">{noticia.titulo}</h2>
                <h5 className="pb-4">{noticia.subtitulo}</h5>
                <p className="text-white bg-primary ps-2 p-1 fw-bold mb-5">{fechaPublicacion.toLocaleDateString("es-ES")} - Autor: {noticia.autor}</p>
                <div className="text-justify">
                    <img src={"https://gestionformativa.es/"+noticia.imagen1} alt={noticia.titulo} className="w-50 float-end ms-5 mb-4" />      
                    <div dangerouslySetInnerHTML={{ __html: noticia.cuerpo }}></div>
                </div>
                <div className="ms-md-5 row w-100">
                <h4 className="col-md-12 ms-5 mt-5 mb-4 text-secondary">Fórmate con nosotros:</h4>
                    {noticia.tag_nombre !== false
                        ? <TagsOposicion titulo={noticia.tag_nombre} imagen={imagenTag}
                                         precio_normal={noticia.tag_precio}
                                         porc_aprobados={noticia.tag_porc_aprobados}
                                         precio_final={noticia.tag_precio_final}
                                                                   oposiciones={noticia.tag_oposiciones} error=""
                                                                   isLoaded="true" colxl="6" colmd="6"/>
                        : <Col md={6}><img className="w-100" src={formate_img} alt="Fórmate con nosotros" title="Fórmate con nosotros" /></Col>
                    }
                    <Col md={6}>
                        <div className="p-3 ps-4 pe-4 h-100 w-100 pb-5" style={{width: 85+"%" }}>
                            <div className="row">
                                <img src="https://opositaformacion.com/img/libro_fisico_y_digital.png" className="col-2" width="60" alt="libros" />
                                <p className="col-10 m-auto"> Disponemos de todo el temario en varios formatos para que pueda estudiar cómo, cuándo y dónde quiera.</p>
                            </div>
                            <div className="row">
                                <img src="https://opositaformacion.com/img/Icono_juego_de_oposiciones.png" className="col-2" width="60" alt=" auriculares" />
                                <p className="col-10 m-auto"> Le ayudamos a hacer una planificación de repaso eficaz durante los meses previos al examen oficial</p>
                            </div>
                            <div className="row">
                                <img src="https://opositaformacion.com/img/videoconferencia.png" className="col-2" width="60" alt="pantalla con pinceles" />
                                <p className="col-10 m-auto"> Los docentes realizan videoconferencias sobre temas relacionados con el temario</p>
                            </div>
                            <div className="row">
                                <img src="https://opositaformacion.com/img/simulacro_de_examen.png" className="col-2" width="60" alt="lista de tareas" />
                                <p className="col-10 m-auto"> Nuestra plataforma dispone de múltiples simulacros de examen y actividades interactivas</p>
                            </div>
                        </div>
                    </Col>
                </div>
            </>
        )
    }
}
