import React from "react";
import { useParams } from 'react-router-dom';
import imggg from "../../../img/imggg.png";
import CursoCategoriasList from "./CursoCategoriasList";
import DondeEstamosView from "../partes/DondeEstamosView";

export default function CursosFPCategorias() {

    // Utiliza `useParams` para obtener el valor del parámetro de ruta ":id"
    const { idcategoria, categoria } = useParams();
    const id = 43;

    return (
        <>
            <div style = {{ height: 110 }}></div>
            <section className = "quiensoy" id = "quiensoy">
                <img src = { imggg } id = "list-title-container" alt = "" />
                <div className = "me-lg-5 ms-lg-5" >
                    <div className = "row mt-lg-1 me-0 ms-0 justify-content-center">
                        <div className = "col-sm-4 me-4 pe-5 container-text-aligned">
                            <h1>Ciclos Formativos de <br /><b className = "text-yellow">{categoria}</b></h1>
                            <div className = "bottom-line"/>
                        </div>
                        <div className = "col-sm-4 ms-4 container-text-aligned">
                            <div> Ciclos formativos ofertados para la categoría de <b className = "text-yellow">{categoria}</b>. </div>
                            <div> Podrás encontrar ciclos de Formación Profesional tanto de Grado Medio como Superior, en diferentes modalidades como presencial u online, y en uno de nuestros diferentes centros. </div>
                        </div>
                    </div>
                </div>
            </section>
            <CursoCategoriasList idcategoria = { idcategoria } categoria = { categoria }></CursoCategoriasList>
            {/*<DondeEstamosView id={null}/>*/}
            <DondeEstamosView id = { id }></DondeEstamosView>
        </>
    );
}
