import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Faqs() {

    return (
            <div className = "text-white presentation presentation-quienes pt-5">
                <div className = "infopoliticas">
                    <div className = "cuadro-borde m-1 ms-4 me-4 mt-3 mb-1 p-3 pt-4" id = "accordion">
                        <h1 className = "elegant-line-bottom text-center"> Preguntas Frecuentes </h1>
                        <>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel0a-content"
                                        id = "panel0a-header">
                                        <h3 className = "Details-title js-details-title"> ¿Los tests de oposiciones de OpositaTest son gratuitos? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p className = "acc-text">
                                            A partir de enero 2023 Instituto FP-Prime tiene tres convocatorias al año, pudiéndose matricular en ellas desde el mes anterior hasta los tres meses del inicio.
                                            Si usted se matricula el mes anterior a la apertura de la convocatoria, tendrá que esperar al primer lunes del mes siguiente para tener acceso al contenido.
                                            Sin embargo, si se matricula en una convocatoria que ya está en curso, debe abonar los meses atrasados desde el inicio de esta y tendrá disponible las webinar grabadas durante esos meses y los temas abiertos hasta el momento.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel1a-content"
                                        id = "panel1a-header">
                                        <h3 className = "Details-title"> ¿Y si sale el examen de oposición antes de que finalice mi preparación? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            Si usted está matriculado con nosotros y sale fecha de examen, no se preocupe, le daremos todos los medios necesarios para realizar la mejor preparación para conseguir su objetivo.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel2a-content"
                                        id = "panel2a-header">
                                        <h3 className = "Details-title js-details-title"> Y si sale fecha de examen, ¿No se abre nueva convocatoria? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            Si en algún momento sale fecha de examen de su comunidad y consideramos que hay el tiempo necesario para poder preparar el examen, abriremos convocatorias extraordinarias de duración variable.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel3a-content"
                                        id = "panel3a-header">
                                        <h3 className = "Details-title js-details-title"> ¿Cómo funciona el campus de fpprime? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            En el encabezado del campus, tienen a su disposición un vídeo explicativo del mismo, para cualquier otra duda contacten con: &nbsp;
                                            <a className = "highlight-emails" href="mailto:seguimiento@fpprime.es">seguimiento@fpprime.es</a>
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel4a-content"
                                        id = "panel4a-header">
                                        <h3 className = "Details-title js-details-title"> ¿Cómo es la metodología de estudio? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            Sobre la metodología de estudio tienen un vídeo explicativo en el inicio del campus, pero a groso modo el cronograma se divide por semanas de estudio, siendo la semana 1 la primera en la que tiene usted acceso al campus y la semana 39 la última. Y todas estas semanas se dividen en tres vueltas de estudio. En la primera vuelta, cada semana tendremos que estudiar con detalle un solo tema, o como mucho, un tema de la parte común y otro tema de la parte específica de nuestra oposición. El motivo de dedicarle una semana a cada tema es que, de esta forma, fijamos todos los conocimientos del tema de forma detallada según la Metodología de la Curva del Olvido.
                                        </p>
                                        <p>
                                            Aun así, cada persona tiene su propio método de estudio, pero desde Instituto FP-Prime, queremos proponer, en líneas generales, una metodología para afrontar cada uno de los temas:
                                        </p>
                                        <ul>
                                            <li className = "espaceli">
                                                Lo primero es realizar una lectura previa del tema, con el fin de tener unas nociones generales del mismo.
                                            </li>
                                            <li className = "espaceli">
                                                Para después realizar una lectura comprensiva del tema: por ejemplo, con el subrayado del mismo con diferentes colores, que después asociemos a los conceptos estudiados o la realización de nuestros propios esquemas.
                                            </li>
                                            <li className = "espaceli">
                                                Y para posteriormente, repasar lo estudiado siguiendo la metodología de “La Curva del Olvido” de Hermann Ebbinghaus, filósofo y psicólogo alemán realizó diversos estudios sobre la memoria. Y uno de sus estudios más importantes fue “La curva del olvido”, en la cual se propuso aprender una serie de palabras e ir recordándolas a lo largo del tiempo sin volver a estudiarlas. Y si enlazamos la curva del olvido con la preparación de su oposición, obtendremos unos resultados óptimos del estudio, por ello debe seguir los siguientes tips para conseguir que sus temas pasen de la memoria a corto plazo a la memoria al largo plazo. Cuando estudie un tema nuevo, siempre repáselo al final de ese día, al día siguiente, a la semana siguiente y al mes siguiente.
                                            </li>
                                            <li className = "espaceli">
                                                Después de los repasos, también debemos realizar test de Autoevaluación, casos prácticos y ejercicios de relleno para fijar los conceptos. Revisando todas y cada una de las respuestas, tanto las erróneas como las correctas, porque le ayudará a afianzar sus conocimientos.
                                            </li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel5a-content"
                                        id = "panel5a-header">
                                        <h3 className = "Details-title js-details-title"> ¿Cómo debo planificar mi estudio? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            Durante la primera vuelta cada día de la semana tiene asignada una tarea específica, siempre apoyado con webinars en directo cada dos semanas aproximadamente.</p>
                                        <ul>
                                            <li className = "espaceli">
                                                El Lunes empieza con la lectura del tema, cada lunes se le abrirá en el campus uno o dos temas según cronograma.
                                            </li>
                                            <li className = "espaceli">
                                                El Martes debe continuar con la lectura comprensiva y la realización de sus propios apuntes, tarjetas y esquemas, que le facilitarán las posteriores fases de repaso.
                                            </li>
                                            <li className = "espaceli">
                                                El Miércoles debe repasar lo estudiado, siguiendo la metodología de la Curva del Olvido.
                                            </li>
                                            <li className = "espaceli">
                                                El Jueves continua con el estudio realizando los primeros test de Autoevaluación.
                                            </li>
                                            <li className = "espaceli">
                                                El Viernes realiza los ejercicios de relleno, con las palabras clave, que permiten valorar lo memorizado y lo que se necesita repasar todavía.
                                            </li>
                                            <li className = "espaceli">
                                                El Sábado vuelve a tener más test de autoevaluación disponibles para seguir repasando lo estudiado esa semana. Y también recomendamos repasar la técnica test repitiendo los test en los que más fallos se cometieron en esa semana.
                                            </li>
                                            <li className = "espaceli">
                                                Y por último, el Domingo a pesar de ser el día de descanso, como actividad recomendada proponemos visualizar todo el material didáctico disponible en la plataforma sobre el tema estudiado esa semana.
                                            </li>
                                        </ul>
                                        <p>
                                            Durante la segunda vuelta se volverá a estudiar cada tema y realizando actividades complementarias sobre los mismos. Empezando a realizar algún simulacro de examen y aprovechando los recursos didácticos disponibles. También tendrá disponible en esta vuelta las sesiones Webinar en directo, para poder resolver todas las dudas que se le planteen con un docente experimentado, repasando los bloques más importantes de la oposición.
                                        </p>
                                        <p>
                                            Y durante la tercera vuelta, cada día de la semana marcado según el cronograma se abrirán nuevos simulacros de examen, insistiendo en la importancia de realizar las actividades disponibles en la plataforma y en preguntar las dudas a sus tutores o tutoras correspondientes. También tendrá disponible en esta vuelta las sesiones Webinar en directo, sobre todo enfocadas a la mejora de la técnica test.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel6a-content"
                                        id = "panel6a-header">
                                        <h3 className = "Details-title js-details-title"> ¿Por qué no tengo abiertos todos los temas desde el inicio? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            El equipo docente de Instituto FP-Prime establecerá cuáles son los temas a estudiar semanalmente y cada oposición tiene su propia programación, adecuada a la cantidad de temas que la componen y a la dificultad de cada uno de ellos, para que en un período de nueves meses, pueda usted preparar la misma y con ello, llegar a la fecha del examen con el temario convenientemente preparado. Desde el momento en que se matricula, tiene establecido los temas que va a estudiar, combinando los dos bloques, los temarios de la parte general y los de la parte específica, pero no tendrá acceso a todos ellos desde el inicio de la oposición, sino que se seguirá un cronograma detallado y en función de esas fechas tendrá usted disponibles los recursos correspondientes. Una vez que entra en el campus y selecciona su categoría y comunidad autónoma, encontrará que se abre una nueva pestaña, en la que se visualiza el cronograma de estudio correspondiente y este es un documento muy importante que debemos tener siempre al alcance de nuestra vista, cuya explicación la encuentra en la Guía del Alumno/a.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel7a-content"
                                        id = "panel7a-header">
                                        <h3 className = "Details-title js-details-title"> ¿Por qué no puedo hacer simulacros desde el inicio de la preparación? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            Según nuestra metodología que tiene detallada en la Guía del Alumno/a los simulacros son más eficaces si se realizan en la recta final de la preparación de la oposición, ya que sino se memorizarían las preguntas y sería una forma errónea de aprender. Por ello, es muy importante trabajar según el cronograma tema a tema para estudiar con detalle cada uno de ellos, y realizar las actividades según marca dicho cronograma.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel8a-content"
                                        id = "panel8a-header">
                                        <h3 className = "Details-title js-details-title"> ¿Cómo contacto con mi tutor/a? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            Desde el comienzo de la preparación, cuenta con el apoyo pedagógico de nuestros profesionales y profesorado, por lo que usted tendrá asignado un/una tutor/a para cada bloque, que estará a tu disposición para marcar el ritmo de estudio, actualizar el temario, avisarle de las novedades de la oposición, y aclarar sus dudas de forma rápida.
                                            Si durante toda la preparación le surge alguna duda sobre los contenidos estudiados, los test y los ejercicios asociados a los mismos, diríjase a su tutor especialista para que se los resuelva. Nuestro consejo es que a medida que avance en el tema y en las lecturas del mismo, vaya anotando lo que no entiende para poder preguntarlo tanto en las tutorías mediante el campus, dónde es importante especificar si la duda es de la parte común o de la parte específica, ya que cada tipo de duda lo responde un tutor/a especializado en la materia.
                                            Para contactar con el tutor/a lo más sencillo es enviar el mensaje de manera individual por el campus a través del apartado de tutorías. Recuerde especificar si es una duda de la parte común o específica.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel9a-content"
                                        id = "panel9a-header">
                                        <h3 className = "Details-title js-details-title"> Si realizo un test, ¿Puedo repetirlo posteriormente? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            Desde que tiene acceso al tema, las actividades complementarias están accesibles durante los nueve meses de preparación, puede hacerlos usted y repetirlos tantas veces como lo desee.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div><div className = "faqs-main">
                                <Accordion >
                                    <AccordionSummary
                                        expandIcon = {<ExpandMoreIcon />}
                                        aria-controls = "panel10a-content"
                                        id = "panel10a-header">
                                        <h3 className = "Details-title js-details-title"> ¿Dónde puedo visualizar las webinar grabadas? </h3>
                                    </AccordionSummary>
                                    <AccordionDetails className = "ac-details">
                                        <p className = "acc-text">
                                            Si no puede asistir en directo a la webinar y esta se anuncia que será grabada, unos días posteriores a la clase se sube el vídeo al apartado "webinars" dentro de multimedia, bien de la parte común o bien de la específica dependiendo de la clase que se trate.
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        );
    }