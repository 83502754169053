import React from "react";
import { useParams } from 'react-router-dom';
import CursosList from "./CursoList";
import imggg from "../../../img/imggg.png";
import empresas from "../InfoCiclos/empresas";
import DondeEstamosView from "../partes/DondeEstamosView";
import MasInfoCursoView from "./MasInfoCursoView";
export default function CursosFPPrime() {

    // Utiliza `useParams` para obtener el valor del parámetro de ruta ":id"
    const { id, nombre } = useParams();
    const [ubi, setUbi] = React.useState(null);
    React.useEffect(() => {
        const emp = empresas.find(empresa => empresa.idEmpresa === parseInt(id, 10));
        const ubicacion = emp.ubicacionEmpresa;
        setUbi(ubicacion);
    }, [ubi]);


    return (
        <>
            <div style = {{ height: 110 }}></div>
            <section className = "quiensoy" id = "quiensoy">
                <img src = { imggg } id = "list-title-container" alt = "" />
                <div className = "me-lg-5 ms-lg-5">
                    <div className = "row mt-lg-5 me-0 ms-0 justify-content-center">
                        <div className = "col-sm-4 me-4 pe-5 container-text-aligned">
                            <h1>Ciclos Formativos de <br /><b className = "text-yellow">{nombre}</b></h1>
                            <div className="bottom-line"/>
                        </div>
                        <div className = "col-sm-4 ms-4 container-text-aligned">
                            <div> Ciclos formativos ofertados por <b className = "text-yellow">{nombre}</b>. </div>
                            <div> {nombre} es un centro educativo que imparte cursos de Formación Profesional ubicado en <b className = "text-yellow">{ubi}</b>. </div>
                            <div> Podrás encontrar ciclos de Formación Profesional tanto de Grado Medio como Superior, en diversas categorías como Sanidad o Administración y Gestión. </div>
                        </div>
                    </div>
                </div>
            </section>
            <CursosList id = { id }></CursosList>
            <br />
            <MasInfoCursoView />
            <br />
            <DondeEstamosView id = { id }></DondeEstamosView>
        </>
    );
}
