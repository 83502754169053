import Titulo from "../index/header/Titulo";
import ConocenosInfo from "../index/body/ConocenosInfo";
import AcercaDeFPprimeView from "../index/partes/AcercaDeFPprimeView";
import DondeEstamosView from "../index/partes/DondeEstamosView";
import imgg from "../../img/computers/laptop-from-above.webp"
import MasInfoCursoView from "../index/InfoCiclos/MasInfoCursoView";
import React from "react";

export default function ConocenosView () {
	const aux = 43;
	return (
		<div>
			<img alt="" src = {imgg} className = "text-center pt-3 shadow-carousel" id = "search-container">
			</img>
			<Titulo />
			<AcercaDeFPprimeView />
			<MasInfoCursoView />
			<br />
			<ConocenosInfo />
			{/*<DondeEstamosView id = {null}/>*/}
			<DondeEstamosView id = {aux}/>
		</div>
	);
}