import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Row, Col, Image } from "react-bootstrap";

export default function CarrouselColaboradores() {

    let deviceType;

    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
        deviceType = 'tablet';
    } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)) {
        deviceType = 'mobile';
    } else {
        deviceType = 'desktop';
    }

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1300 },
            items: 5,
        },
        largeDesktop: {
            breakpoint: { max: 1300, min: 1000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 1000, min: 800 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 800, min: 450 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 450, min: 0 },
            items: 1,
        },
    };

    return (
        <Row className = "colaboradores-container ">
            <h1 className = "text-center">Colaboradores</h1>
            <div className = "elegant-line-bottom2"></div>
            <div className = "div_colaboradores">
                <Col>
                    <Carousel
                        removeArrows = {true}
                        removeArrowOnDeviceType = {["tablet", "mobile", 'desktop', 'largeDesktop', 'superLargeDesktop']}
                        ssr = {true} // means to render carousel on server-side.
                        deviceType = {deviceType}
                        responsive = {responsive}
                        showDots
                        dotListClass = "custom-dot-list-style"
                        infinite = {true}
                        autoPlay = {true}
                        autoPlaySpeed = {2000}
                        nav ={true}
                        customTransition="transform 1000ms ease-in-out"
                        transitionDuration = {1000}
                        keyBoardControl = {true}
                        containerClass = "colaborador-container"
                        itemClass = "logositem"
                    >
                        <div className = "colaborador-card">
                            <a className = "colaborador-image" href='https://formacionegs.com'>
                                <Image
                                    alt = 'Business Audit Consulting S.L.'
                                    width = "100%"
                                    src = 'https://gestionformativa.es/img/empresas/egs1_def.png'
                                />
                            </a>
                        </div>
                        {/*<div className = "colaborador-card">*/}
                        {/*    <a className = "colaborador-image" href='https://orionformacion.com'>*/}
                        {/*        <Image*/}
                        {/*            alt = 'Orion E-Learning'*/}
                        {/*            width = "100%"*/}
                        {/*            src = 'https://orionformacion.com/images/orion_formacion.png'*/}
                        {/*        />*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                        <div className = "colaborador-card">
                            <a className = "colaborador-image" href='https://bacformacion.com'>
                                <Image
                                    alt = 'BAC formacion'
                                    width = "100%"
                                    src = 'https://www.formacionegs.com/img/colaboradores/bacformacion.png'
                                />
                            </a>
                        </div>
                        <div className = "colaborador-card">
                            <a className = "colaborador-image" href='https://institutocorocotta.com/'>
                                <Image
                                    alt = 'instituto Corocotta'
                                    width = "100%"
                                    src = '	https://www.institutocorocotta.com/images/logos/instituto_corocotta.png'
                                />
                            </a>
                        </div>
                        <div className = "colaborador-card">
                            <a className = "colaborador-image" href='https://opositaformacion.com/'>
                                <Image
                                    alt = 'Oposita formacion'
                                    width = "100%"
                                    src = 'https://opositaformacion.com/img/opositalogo_menu.png'
                                />
                            </a>
                        </div>
                        <div className = "colaborador-card">
                            <a className = "colaborador-image" href='https://mundioma.com/'>
                                <Image
                                    alt = 'Mundioma'
                                    width = "100%"
                                    src = 'https://www.formacionegs.com/img/colaboradores/mundioma.png'
                                />
                            </a>
                        </div>
                        <div className = "colaborador-card">
                            <a className = "colaborador-image" href="https://mydronfactory.com/">
                                <Image
                                    alt = 'MyDroneFactory'
                                    width = "100%"
                                    src = 'https://www.formacionegs.com/img/colaboradores/mdf.png'
                                />
                            </a>
                        </div>
                        <div className = "colaborador-card">
                            <a className = "colaborador-image" href="https://support4food.com/">
                                <Image
                                    alt = 'Support4Food'
                                    width = "100%"
                                    src = 'https://www.formacionegs.com/img/colaboradores/logoS4F2.png'
                                />
                            </a>
                        </div>
                    </Carousel>
                </Col>
            </div>
        </Row>
    );
}