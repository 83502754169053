import corocotta from "../../../img/aulablanca.jpeg";
const empresas = [
    {
        id: 1,
        idEmpresa: 43,
        nombreEmpresa: "FP-Prime",
        ubicacionEmpresa: "Pontevedra",
        direccionEmpresa: "Rúa Santa Clara nº21, 36002 Pontevedra, Pontevedra.",
        mailEmpresa: "info@fpprime.es",
        horarioEmpresa: "L-V de 09:00 a 14:00 - ",
        telfEmpresa: "666 583 232",
        tel2callEmpresa: "666583232",
        telfSecundario: "690 942 555",
        tel2callSecundario: "690942555",
        url: "https://media.licdn.com/dms/image/D4D22AQEpsbiuDVNTqw/feedshare-shrink_800/0/1698320754036?e=2147483647&v=beta&t=Og4JitG2aKccLu1eqZhFEeUN0ABVAPiaO5bHRjzoiYs"
    } /*,
    {
        id: 2,
        idEmpresa: 40,
        nombreEmpresa: "Corocotta",
        ubicacionEmpresa: "Cantabria",
        direccionEmpresa: "C/ Santa Teresa 3B piso 1º C.P. 39600 Maliaño (Camargo), Cantabria.",
        mailEmpresa: "secretaria@institutocorocotta.com",
        horarioEmpresa: "L-V de 09:00 a 14:00 - ",
        telfEmpresa: "621 073 922",
        tel2callEmpresa: "621073922",
        url: corocotta
    },
    {
        id: 3,
        idEmpresa: 2,
        nombreEmpresa: "Orion",
        ubicacionEmpresa: "Granada",
        direccionEmpresa: "C/ Santa Rosalía, 14 local 2. 18007 Granada, Granada.",
        direccionDelegacion: "Avda. Antonio Mairena Nº41, 1º izq., 41500 Alcalá de Guadaíra, Sevilla",
        mailEmpresa: "info@orionformacion.com",
        horarioEmpresa: "L-J de 07:30 a 13:30 y 16:00 a 20:30, V de 7:30 a 13:30 - ",
        telfEmpresa: "698 133 048",
        tel2callEmpresa: "698133048",
        url: "https://orionformacion.com/images/popular_categories/pc_9.jpg"
    }*/
];

export default empresas;
