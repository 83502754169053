import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import config from "../../../config/apiConfig";
import validaDatos from "../../../functions/Comprobaciones";
import Loader from "../../general/Loader";
import {NumberFormatter} from "react-number-formatter";
import ReCAPTCHA from "react-google-recaptcha";

export default function ModalPreinscripcionView({ showModal }) {

    ///////////////////////
    //     VARIABLES     //
    ///////////////////////
    const navigate = useNavigate();
    const [name, setName ]= useState('');
    const [email, setEmail ]= useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const myform = document.getElementById('form-preinscripcion');
    const recaptcha = useRef(null);
    const checkboxcheckedd  = useRef(null);
    const aux = 43;
    const [categoriasCursoEmpresa, setCategoriasCursoEmpresa] = useState([]);
    const data = {
        action: 'devuelveCursos', // devuelveCursosEmpresaAcreditadora
        idEmp: aux,
    };

    /***********************************************************
     * UseEffect carga los datos del select por medio de fetch *
     * *********************************************************/
    useEffect(() => {
        const fetchData = async () => {
            try {
                // En vez de categorias tengo que devolver cursos
                const response = await fetch(config.API_BASE_URL + '/curso.php?idempresa=43&trcce_id='+12, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setCategoriasCursoEmpresa(result);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();

    }, []); // Obtener datos cuando cambia categoria

    /*
     * Función que setea los datos de select
     * **/
    const renderOpts = () => {
        if(categoriasCursoEmpresa) {
            return categoriasCursoEmpresa.map(curso => (
                <option value={curso.trcce_id} >{curso.nombrecursoacreditado}</option>
            ));
        }
    };

    /*
     * Función para enviar los datos
     * **/
    async function handleSubmitt(event) {
        event.preventDefault(); // Prevenir redirección

        let opt = recaptcha.current.getValue();
        // Validar los datos del formulario
        if (validaDatos(name, email, phone, checkboxcheckedd.current.checked, opt, "preinscripcion")) {
            setLoading(true);

            var recibido = document.getElementById("recibida");
            var select = document.getElementById("selectorCurso2");
            const idcurso = select.value;
            const nombre_curso = select.options[select.selectedIndex].text;

            // Enviar datos a la API
            const formData = {
                nombre: name,
                email: email,
                telefono: phone,
                nombre_curso: nombre_curso,
                trcce_id: idcurso,
                idempresa: aux,
                tokenRecaptcha: opt,
                tipoSolicitud: "preinscripciones_fpprime"
            };

            // En vez de categorias tengo que devolver cursos
            await fetch(config.API_BASE_URL + '/alumnos.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(result => {
                    if (result) {
                        setLoading(false);
                        // Ocultar formulario y mostrar botón de descarga
                        if (myform) {
                            myform.classList.add("d-none");
                            recibido.classList.remove("d-none");
                        }
                        // Enviar correo electrónico y generar PDF (en el servidor backend)
                    } else {
                        // Mostrar mensaje de error
                        console.log("ERROR: " + result);
                        setLoading(false); // Ocultar loader al finalizar fetch con error
                        navigate('/algo-mal'); // Redirigir a /algo-mal
                    }
                })
                .catch((error) => {
                    // Manejar error
                    console.log("ERROR CATCH: " + error);
                    setLoading(false); // Ocultar loader al finalizar fetch con error
                    navigate('/algo-mal'); // Redirigir a /algo-mal
                });
        }
    }

    /*
     * Función que indica si se ha clickado el checkbox
     * **/
    function clickCheckBox() {
        if(checkboxcheckedd.current.checked){
            document.getElementById("btnreserva").classList.remove("disabled");
            document.getElementById("msgacept2").classList.add("d-none");
        }
        else {
            document.getElementById("btnreserva").classList.add(("disabled"));
            document.getElementById("msgacept2").classList.remove("d-none");
        }
    }

    /*
     * Función para cerrar el Modal
     * **/
    function cerrarModal() {
        const aux = document.getElementById("btn-preinscripcion");
        if(aux) {
            aux.classList.add("d-none");
            aux.classList.remove("openmodal");
        }
    }


    /**************
     *   RETURN   *
     **************/
    return (
        <div className = "d-none container-info" id = "btn-preinscripcion">
            <div className = "showmodal">
                <div className = "overlay"></div>
                {loading && <Loader />}
                <div className = "myContactForm bg-color-gs-secondary-a widget widget-quick-contact">
                    <button className = "modal_close" onClick = { cerrarModal }>✕</button>
                    <div className = "title text-center">¡Reserva ya tu plaza!</div>
                    <div id = "recibida" className = "lead-result d-none insert-video margin-form" style = {{ minHeight: 350 }}>
                        <div className = "text-center">
                            <br/>
                            <h3>Estás a un paso de formar parte de FP-Prime.</h3>
                            <br/>
                            Tu solicitud ha sido enviada.
                            <br/><br/>
                            En breves nuestro equipo se pondrá en contacto contigo y te indicará los pasos a seguir para formalizar tu preinscripción y asegurarte tu plaza en el ciclo.
                            <br/><br/>
                            Gracias por confiar en FP-Prime.
                        </div>
                    </div>
                    <form className = "body margin-form row flat-contact-form" id = "form-preinscripcion" onSubmit = { handleSubmitt }>
                        <input type = "name" tabIndex="0" aria-label = "name" placeholder = "Nombre" name = "name_user" id = "name-contact" required onChange = {(e) => setName(e.target.value)}/>
                        <input type = "email" tabIndex="0" aria-label = "email" placeholder = "Escriba su email" name = "email_user" id = "email-contact2" required onChange = {(e) => setEmail(e.target.value)}/>
                        <span id = "email-contact-help2" className = "error-text small"></span>
                        <NumberFormatter
                            tabIndex="0" name = "phone_user" id = "phone-contact"
                            placeholder = "Teléfono"
                            required = "true"
                            aria-required = "true"
                            format={"+34 ### ### ###"}
                            aria-label= "phone_number"
                            value={phone}
                            getValue={(n) => setPhone(n)}
                        />
                        <span id = "phone-contact-help2" className = "error-text small"></span>
                        <select id = "selectorCurso2" placeholder = "¿Qué curso te interesa?" required name = "selector_user">
                            <option hidden value = "">¿Qué ciclo te interesa?</option>
                            {renderOpts()}
                        </select>
                        <div className = "error d-none error-select24">Escoge la formación en la que estás interesado</div>
                        <span id = "formacion-contact-help2" className = "error-text small"></span>
                        <p className = "pb-3 pt-3 m-0 w-100 justify-content-start align-content-start textwidth txt-gray">
                            <input id = "ckbprivacidad" type = "checkbox" aria-label = "ckbprivacidad" onClick={clickCheckBox} ref={checkboxcheckedd}/>
                            &nbsp; He leído y acepto los <a className = "color-enlace txt-bold" href = "/terminos-condiciones"> Términos y Condiciones</a> y deseo recibir información comercial.
                        </p>
                        <p id = "msgacept2" className = "d-none alert-danger error-text small "> Debe aceptar la Política de privacidad. </p>
                        <ReCAPTCHA sitekey = { config.RECAPTCHA_SITE_KEY } ref = { recaptcha }/>
                        <p id = "recaptgacept2" className = "d-none alert-danger error-text small "> Verifique que no es un bot. </p>
                        <div className = "submit-wrap mt-3 mb-4">
                            <button id = "btnreserva" className = "btn btn-outline-warning btn-sm w-100" style = {{ position: "inherit" }} type = "submit">
                                <span className = "txt-bold"> RESERVAR </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}