import { useEffect } from "react";
import { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import config from "../../../config/apiConfig";

export default function Noticia(props) {
    const [error, setError]         = useState(null);
    const [isLoaded, setIsLoaded]   = useState(false);
    const [noticias, setNoticias]     = useState([]);

    useEffect(() => {
        fetch(config.API_BASE_URL+'/noticias.php?codigoempresa=8942LP29432PQ44&tipo=lista&ev='+props.noticia_id+'&limit=10')
        .then(response => response.json())
        .then((result) => {
            setIsLoaded(true);
            setNoticias(result);
        })
        .catch(error => {
            setIsLoaded(true);
            setError(error);
        });
    }, [])

    if (error) {
        return (
            <div>Error en la carga</div>
        )
    } else if (!isLoaded) {
        return <div className="m-auto text-center mt-5"><Spinner animation="grow" variant="dark" /></div>;
    } else {

        return (
            <>
                {noticias.map((noticia, index) => (
                    <div className="card w-100 text-center mb-4" key={index}>
                        <img src={"https://gestionformativa.es/" + noticia.imagen1} className="card-img-top"
                             alt={noticia.titulo}/>
                        <div className="card-body">
                            <h5 className="card-title">{new Date(noticia.fechapublicacion).toLocaleDateString("es-ES")} {noticia.titulo}</h5>
                            <p className="card-text">{noticia.subtitulo}</p>
                            <a href={"/noticia/"+ noticia.id} className="btn btn-primary">Ir a la Noticia</a>
                        </div>
                    </div>
                ))}
            </>
        )
    }
}
