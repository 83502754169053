import React from "react";
import img from "../../../img/meetings/co-working.webp";

export default function TrabajaConFPprime () {

    return (
        <div className="pt-3 mt-5 mb-5">
            <div className = "me-lg-5 ms-lg-5">
                <div className = "row mt-lg-5 me-0 ms-0 justify-content-center">
                    <div className = "col-sm-4 text-center me-4 mb-4 img-index">
                        <img className = "text-center mt-4 mb-4" id = "img-info-container" style={{borderRadius: 15}} alt="" src={img}>
                        </img>
                    </div>
                    <div className = "col-sm-4 ms-4 container-text-aligned">
                        <h1>¿Quieres formar parte del equipo de FP-Prime?</h1>
                        <span>En FP-Prime buscamos a profesionales comprometidos que quieran formar parte de este proyecto.
                            <br/>En él, podrás desarrollarte como docente acompañando a los alumnos en su desarrollo profesional.
                            <br/>¿Dispones de experiencia y/o formación en el departamento de RRHH o cuentas con competencia docente en alguno de nuestros campos?
                            <br/>Si es así y quieres unirte a nuestro equipo, envíanos un correo a <a className = "conoce-link text-decoration-none" href = "mailto:info@fpprime.es">info@fpprime.es</a> diciéndonos por qué quieres formar parte de nuestro equipo e incluyendo tu currículum. ¡Te esperamos! </span>
                        <div className = "bottom-line"/>
                    </div>
                </div>
            </div>
        </div>
    );
}