import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import config from "../../../config/apiConfig";
import PqIconosView from "../partes/PqIconosView";
import LogoMinisterio from "../../../img/general/LogoMinisterio.png";
import MasInfoCursoView from "./MasInfoCursoView";

export default function VistaCurso() {

    // Utiliza `useParams` para obtener el valor del parámetro de ruta ":id"
    const {id, idEmpresa} = useParams();
    const requisitos = {
        medio: {
            titulos: [
                "- Título de Graduado/a en Educación Secundaria Obligatoria o de un nivel académico superior.",
                "- Título Profesional Básico (Formación Profesional de Grado Básico).",
                "- Título de Técnico/a o de Técnico/a Auxiliar o equivalente a efectos académicos."
            ],
            superado: [
                "- 2º curso del Bachillerato Unificado y Polivalente (BUP).",
                "- Prueba de acceso a ciclos formativos de grado medio (se requerira tener, al menos, diecisiete años, cumplidos en el año de realización de la prueba).",
                "- Prueba de acceso a la Universidad para mayores de 25 años (la superación de las pruebas de acceso a la Universidad para mayores de 40 y 45 años no es un requisito válido para acceder a FP)."
            ]
        },
        superior: {
            titulos: [
                "- Bachiller.",
                "- Técnico Superior de Formación Profesional o grado universitario.",
                "- Técnico de Grado Medio de Formación Profesional o el título de Técnico o Técnica de Artes Plásticas y Diseño."
            ],
            superado: [
                "- Una oferta formativa de Grado C incluida en el ciclo formativo.",
                "- Un curso de formación específico preparatorio y gratuito para el acceso a ciclos de grado superior en centros expresamente autorizados por la Administración educativa.",
                "- Una prueba de acceso."
            ]
        }
    };
    const [curso, setCurso] = useState([]);
    const [nivel, setNivel] = useState([]);
    const [horario, setHorario] = useState([]);
    const [denominacion, setDenominacion] = useState([]);
    const data = {
        action: 'getInfoCurso',
        idEmp: idEmpresa,
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // En vez de categorias tengo que devolver cursos
                const response = await fetch(config.API_BASE_URL + '/curso.php?idempresa=43&trcce_id='+id, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setCurso(result);
            } catch (error) {
                console.error('Error:', error);
            }
        }

        fetchData();
    }, [id]); // Obtener datos cuando cambia categoria

    useEffect(() => {
        if(Object.keys(curso).length > 0) {
            const nuevaDescripcion = dividircontenido(curso.descripcion);
            let midescripcion = [];
            for (let propiedad in nuevaDescripcion) {
                midescripcion.push(propiedad);
            }
            rellenaDiv(curso.objetivosgenerales, "objetivosDiv");
            rellenaDiv(curso.indice, "planformacion");
            if(curso.titulo.includes("Medio")){
                rellenaDiv(requisitos.medio.titulos, "accesoDiv");
                rellenaDiv(requisitos.medio.superado, "acceso2Div");
                setNivel("Formación Profesional de Grado Medio.");
                setHorario("Formación Presencial en Horario de Mañana.");
            }
            else {
                rellenaDiv(requisitos.superior.titulos, "accesoDiv");
                rellenaDiv(requisitos.superior.superado, "acceso2Div");
                setNivel("Formación Profesional de Grado Superior.");
                setHorario("Formación Presencial en Horario de Tarde.");
            }
            const titulo = curso.titulo;
            const regex = /Grado (Medio|Superior) en /; // Eliminar "Grado Medio en" o "Grado Superior en"
            setDenominacion(titulo.replace(regex, ""));
            rellenaDiv(nuevaDescripcion[midescripcion[0]], "identificaciontituloDiv");
            rellenaDiv(nuevaDescripcion[midescripcion[1]], "competenciageneralDiv");
            rellenaDiv(nuevaDescripcion[midescripcion[2]], "entornoProfesionalDiv");
            rellenaDiv(nuevaDescripcion[midescripcion[3]], "ocupacionesDiv");
            // cambiarContenido();
        }
    }, [curso]);


    /**********************************************
     * Sticky de la barra de contenidos del curso *
     * ********************************************/
    window.addEventListener("scroll", function() {
        let header = document.getElementById("menu-curso-div");
        header.classList.toggle("sticky", window.scrollY > 386); //850
    });


    /********************************************
     * Función que divide el contenido devuelto *
     * ******************************************/
    function dividircontenido(este) {
        let div = document.createElement('div');
        div.innerHTML = este;
        let nuevaDescripcion = {};
        let currentTitle = null;
        let currentContent = [];

        Array.from(div.childNodes).forEach((node) => {
            if (node.nodeName === 'P') {
                let strong = node.querySelector('strong');
                if (strong) {
                    if (currentTitle) {
                        nuevaDescripcion[currentTitle] = currentContent.length === 1 ? currentContent[0] : currentContent;
                    }
                    currentTitle = strong.textContent.trim().toLowerCase().replace(/\s/g, '');
                    currentContent = [];
                } else {
                    currentContent.push(node.textContent.trim());
                }
            }
        });
        if (currentTitle) {
            nuevaDescripcion[currentTitle] = currentContent.length === 1 ? currentContent[0] : currentContent;
        }
        return nuevaDescripcion;
    }

    /*******************************************
     * Función que cambia el contenido visible *
     * *****************************************/
    function cambiarContenido() {
        irArribaContenido();
        let buttons2 = document.querySelectorAll(".menu-curso-row div");
        if (buttons2) {
            buttons2.forEach(button => {
                button.addEventListener("click", _ => {
                    buttons2.forEach(button => {
                        button.classList.remove("isactive");
                        // Eliminar espacios y convertir a minúsculas
                        let txt = (button.children[0].textContent).replace(/\s/g, '').toLowerCase();
                        if (document.getElementById(txt))
                            document.getElementById(txt).classList.add("noestoy");
                    })
                    button.classList.toggle("isactive");
                    // Eliminar espacios y convertir a minúsculas
                    let txt = (button.children[0].textContent).replace(/\s/g, '').toLowerCase();
                    if (document.getElementById(txt))
                        document.getElementById(txt).classList.remove("noestoy");
                });
            });
        }
    }

    /*******************************************
     * Función que vuelve hacia arriba para ver los menus *
     * *****************************************/
    function irArribaContenido() {
        window.scrollTo({
            top: 350,
            behaviour: "smooth"
        });
    }

    /**
     * Función para abrir el modal de solicita info
     */
    function clickPreinscripcion () {
        const aux = document.getElementById("btn-preinscripcion");
        if(aux) {
            if(aux.classList.contains("d-none")){
                aux.classList.remove("d-none");
            }
            aux.classList.add("openmodal");
        }
    }

    /*******************************************
     * Función que rellena los div de la vista *
     * *****************************************/
    function rellenaDiv(apartadoCurso, nombreDiv) {
        // Obtén el elemento div
        const competenciasDiv = document.getElementById(nombreDiv);

        if (competenciasDiv) {
            // Limpia el contenido existente del div antes de agregar nuevos elementos
            competenciasDiv.innerHTML = '';
            const competenciasLista = document.createElement('ul');
            // Verifica si curso.competencias es un string o un array de strings
            if (typeof apartadoCurso === 'string') {
                // Si es un string, simplemente asigna el contenido al div
                competenciasLista.innerHTML = apartadoCurso;
                if (apartadoCurso.startsWith('<p>- ') || apartadoCurso.startsWith('<p>a) ')) {
                    competenciasLista.classList.add("margin-side");
                }
                // competenciasLista.classList.add("margin-side");
                competenciasDiv.appendChild(competenciasLista);
            } else if (Array.isArray(apartadoCurso) && apartadoCurso.length > 0) {
                // Si es un array de strings, crea una lista con puntos
                let aux = 0;
                apartadoCurso.forEach(competencia => {
                    let trimmedCompetencia = competencia.trim();
                    if (trimmedCompetencia.endsWith(':') || (aux === 0 && (!trimmedCompetencia.startsWith('- ') && !/^[a-z]\) /.test(trimmedCompetencia)))) {
                        aux = 0;
                        const competenciaItem = document.createElement('li');
                        competenciaItem.classList.add("margin-li");
                        competenciaItem.textContent = competencia;
                        competenciasLista.appendChild(competenciaItem);
                    } else {
                        aux = 1;
                        const competenciaItem = document.createElement('li');
                        competenciaItem.classList.add("margin-li");
                        competenciaItem.classList.add("margin-side");
                        competenciaItem.textContent = competencia;
                        competenciasLista.appendChild(competenciaItem);
                    }
                    // Agrega la lista al div
                    competenciasDiv.appendChild(competenciasLista);
                });

            } else {
                // Si no es un string ni un array de strings, puedes manejarlo según tus necesidades
                if(nombreDiv === "accesoDiv"){
                    competenciasLista.textContent = 'No hay requisitos de acceso especificados.';
                    competenciasDiv.appendChild(competenciasLista);
                }
                else {
                    competenciasLista.textContent = 'Apartado no disponible.';
                    competenciasDiv.appendChild(competenciasLista);
                }
            }
        }
    }

    return (
        <>
            <div className = "text-center shadow-carousel" id = "curso-container" >
                <div className = "capa"></div>
                <img src = { curso.imagen } alt = "Des" id = "curso-container"></img>
                <div className = "text-white title-style-curso">
                    <h1 className = "fondo-txt"> { curso.titulo } </h1>
                    <h5 className = "fondo-txt text-yellow"> { curso.nombrecategoria } </h5>
                </div>
            </div>
            <div className = "mt-lg-3 mb-lg-5 menu-curso">
                <div id = "menu-curso-div" >
                    <div className = "row me-0 ms-0 justify-content-center text-center menu-curso-row" id = "menu-curso-row">
                        <div className = "col txt-menu-curso isactive" onClick = {() => cambiarContenido()}>
                            <h6>Información Principal</h6>
                        </div>
                        <div className = "col txt-menu-curso" onClick = {() => cambiarContenido()}>
                            <h6>Requisitos de Acceso</h6>
                        </div>
                        <div className = "col txt-menu-curso" onClick = {() => cambiarContenido()}>
                            <h6>Objetivos Generales</h6>
                        </div>
                        <div className = "col txt-menu-curso" onClick = {() => cambiarContenido()} >
                            <h6>Plan de Formación</h6>
                        </div>
                        <div className = "col txt-menu-curso" onClick = {() => cambiarContenido()}>
                            <h6>Salidas Profesionales</h6>
                        </div>
                        <div className = "col txt-menu-curso" onClick = {() => cambiarContenido()}>
                            <h6>Más Información</h6>
                        </div>
                    </div>
                </div>
            </div>
            {/* SECCION 1: Informacion principal */}
            <section className = "quiensoy" id = "informaciónprincipal">
                <div className = "me-lg-5 ms-lg-5" style = {{ display: "grid" }}>
                    <div className = "row mb-lg-5 me-0 ms-0 justify-content-center" id = "row-size-div">
                        <div className = "col-sm-8 container-text-aligned">
                            <h2> Información Principal. </h2>
                            <div className = "bottom-line-curso" ></div>
                            <div className="col-12 ">
                                <div className="row">
                                    <div className="col-sm-6 info-ppal-item">
                                        <div className="info-ppal-number">1</div>
                                        <div className="info-ppal-text px-3"><h4>Inicio</h4> 9 de Septiembre de 2024. </div>
                                    </div>
                                    <div className="col-sm-6 info-ppal-item">
                                        <div className="info-ppal-number">2</div>
                                        <div className="info-ppal-text px-3"><h4>Denominación</h4> { denominacion }.</div>
                                    </div>
                                    <div className="col-sm-6 info-ppal-item">
                                        <div className="info-ppal-number">3</div>
                                        <div className="info-ppal-text px-3"><h4>Horario</h4> { horario }</div>
                                    </div>
                                    <div className="col-sm-6 info-ppal-item">
                                        <div className="info-ppal-number">4</div>
                                        <div className="info-ppal-text px-3"><h4>Familia Profesional</h4> { curso.nombrecategoria }.</div>
                                    </div>
                                    <div className="col-sm-6 info-ppal-item">
                                        <div className="info-ppal-number">5</div>
                                        <div className="info-ppal-text px-3"><h4>Duración</h4> { curso.horas } Horas.</div>
                                    </div>
                                    <div className="col-sm-6 info-ppal-item">
                                        <div className="info-ppal-number">6</div>
                                        <div className="info-ppal-text px-3"><h4>Nivel</h4> { nivel }</div>
                                    </div>
                                    <div className="col-sm-6 info-ppal-item">
                                        <div className="info-ppal-number">7</div>
                                        <div className="info-ppal-text px-3">
                                            <h4>Titulación Oficial </h4>
                                            <img className = "img-logo-ministerio" src = { LogoMinisterio } alt = "Logo Ministerio de Educación"/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 info-ppal-item">
                                        <div className="info-ppal-number">8</div>
                                        <div className="info-ppal-text px-3">
                                            <h4>Reserva tu plaza </h4>
                                            {/* TODO Cambiar al abrir preinscripciones */}
                                            Próximamente
                                            {/*<a className="btn btn-sm btn-light-blue text-white rounded-btn mybtn" id = "btn-reserva" onClick = { clickPreinscripcion }><span className="myspan"> Preinscríbete </span></a>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* SECCION 2: Requisitos acceso */}
            <section className = "noestoy" id = "requisitosdeacceso" >
                <div className = "me-lg-5 ms-lg-5" style = {{ display: "grid" }}>
                    <div className = "row mb-lg-5 me-0 ms-0 justify-content-center">
                        <div className = "col-sm-8 container-text-aligned">
                            <h2 className = "mt-3"> Requisitos de Acceso. </h2>
                            <div className = "bottom-line-curso" ></div>
                            <p>Debes cumplir <strong>alguno</strong> de los siguientes requisitos:</p>
                            <h3> Tener alguno de los siguientes títulos: </h3>
                            <div id = "accesoDiv"> </div>
                            <h3 className = "mt-3"> Haber superado: </h3>
                            <div id = "acceso2Div"> </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* SECCION 3: Objetivos */}
            <section className = "noestoy" id = "objetivosgenerales" >
                <div className = "me-lg-5 ms-lg-5" style = {{ display: "grid" }}>
                    <div className = "row mb-lg-5 me-0 ms-0 justify-content-center">
                        <div className = "col-sm-8 container-text-aligned">
                            <h2> Objetivos Generales.  </h2>
                            <div className = "bottom-line-curso" ></div>
                            <div id = "objetivosDiv"> </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* SECCION 4: Plan de formacion */}
            <section className = "noestoy" id = "plandeformación" >
                <div className = "me-lg-5 ms-lg-5" style = {{ display: "grid" }}>
                    <div className = "row mb-lg-5 me-0 ms-0 justify-content-center">
                        <div className = "col-sm-4 container-text-aligned">
                            <h2> Plan de Formación.  </h2>
                            <div className = "bottom-line-curso2" ></div>
                            <div id = "planformacion"> </div>
                        </div>
                        <div className = "col-sm-4 ms-4 ps-5 container-text-aligned2">
                            <div className = "mt-3">
                                <img src = { curso.imagen }  alt = "Des" className = "curso-img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* SECCION 5: Salidas profesionales */}
            <section className = "noestoy" id = "salidasprofesionales" >
                <div className = "me-lg-5 ms-lg-5" style = {{ display: "grid" }}>
                    <div className = "row mb-lg-5 me-0 ms-0 justify-content-center">
                        <div className = "col-sm-8 container-text-aligned">
                            <h2> Salidas Profesionales. </h2>
                            <div className = "bottom-line-curso"></div>
                            <h3 className = "mt-3"> Entorno Profesional:  </h3>
                            <div id = "entornoProfesionalDiv"> </div>
                            {/*<h3 className = "mt-3"> Ocupaciones y puestos de trabajo más relevantes: </h3>*/}
                            {/*<div id = "ocupacionesDiv"> </div>*/}
                            <div className="col-md my-4">
                                <div className="info-ppal-card-wrapper mt-4 info-ppal-bordercard ">
                                    <div className="info-ppal-card-title">
                                        <h2>Ocupaciones y puestos <br/>
                                            de trabajo más relevantes:
                                        </h2>
                                    </div>
                                    <div className="info-ppal-card-content">
                                        <div id = "ocupacionesDiv"> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* SECCION 6: Mas informacion */}
            <section className = "noestoy" id = "másinformación"  >
                <div id = "másinformación" className = "me-lg-5 ms-lg-5" style = {{ display: "grid" }}>
                    <div className = "row mb-lg-5 me-0 ms-0 justify-content-center">
                        <div className = "col-sm-8 container-text-aligned">
                            <h2> Más información. </h2>
                            <div className = "bottom-line-curso" ></div>
                            <h3> Competencia General:</h3>
                            <div id = "competenciageneralDiv"> </div>
                            <h3 className = "mt-3"> Identificación del Título: </h3>
                            <div id = "identificaciontituloDiv"> </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* FECHAS PREINSCRIPCION */}
            <div className = "row text-start pt-5 mb-5 pb-5 light-yellow-background justify-content-center">
                <div className = "col-xl-1 col-lg-2 col-3 infoExtra" >
                    <div className = "text-end">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className = "my-icon" onClick = { clickPreinscripcion }>
                            <i className="fa-solid fa-pen-to-square"></i>
                        </a>
                    </div>
                </div>
                <div className = "col-xl-3 col-lg-3 col-7 infoExtra">
                    <h1 className="small txt-bold"> Fechas de Preinscripción</h1>
                    <a onClick = { clickPreinscripcion } className="txt-hover-bold text-decoration-none text-secondary">
                        <h3>
                            {/*Reserva tu plaza <b>hoy</b> mismo. */}
                            Próximamente
                        </h3>
                    </a>
                </div>
                <div className = "col-xl-1 col-lg-2 col-3 infoExtra" >
                    <div className = "text-end">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className = "my-icon" >
                            <i className="fa-solid fa-calendar-check"></i>
                        </a>
                    </div>
                </div>
                <div className = "col-xl-3 col-lg-3 col-7 infoExtra">
                    <h1 className="small txt-bold"> Inicio</h1>
                    <h3 > Próximamente </h3>
                </div>
            </div>
            <PqIconosView />
            <MasInfoCursoView />
            <br />
        </>
    );
}
