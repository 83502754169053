import Body from './pedido/Body.js';
import Header from "./general/Header";

export default function Pedidos() {
    return (
        <>
            <Header fondo="bg-secondary" />
            <Body />
        </>
    );
}