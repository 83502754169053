import React from "react";
import imgg from "../../../img/conocenos/circle-work-journal.webp";
import imgg4 from "../../../img/conocenos/brainstorm-meeting.webp";

export default function AcercaDeFPprimeView () {
    return (
        <div>
            <div className = "me-lg-5 ms-lg-5 pt-5 pb-5 mb-5">
                <div className = "row mt-lg-5 me-0 ms-0 justify-content-center wrap-reverse">
                    <div className = "col-sm-4 me-4 container-text-aligned conoce-col">
                        <h1>Conoce FP-Prime</h1>
                        <span className = "text-large">FP-Prime es un centro privado de Formación Profesional autorizado por la Consellería de Cultura, Educación, Formación Profesional e Universidades para impartir titulaciones oficiales.
                            <br />Disponemos de Ciclos Formativos de Grado Medio y de Grado Superior en diversos ámbitos, centrándonos en los ámbitos de la Sanidad y la Administración y Gestión.</span>
                        <div className = "bottom-line"/>
                    </div>
                    <div className = "col-sm-4 text-center d-flex justify-content-center conoce-col">
                        <img alt = "" src = { imgg } className = "text-center circle-fpprime-img" >
                        </img>
                    </div>
                </div>
                <div className = "row mt-lg-5 me-0 ms-0 justify-content-center animated-img">
                    <div className = "col-sm-4 text-center d-flex justify-content-center conoce-col">
                        <img alt = "" src = { imgg4 } className = "text-center circle-fpprime-img" >
                        </img>
                    </div>
                    <div className = "col-sm-4 me-4 container-text-aligned conoce-col">
                        <h1>Nuestro Compromiso</h1>
                        <span className = "text-large">En FP-Prime estamos comprometidos con la búsqueda de la excelencia en nuestras acciones formativas. Esta se sustenta en tres pilares fundamentales:</span>
                        <ul>
                            <li className = "margin-li margin-side">
                                - El empleo de metodologías adaptadas a las necesidades e intereses de nuestro alumnado.
                            </li>
                            <li className = "margin-li margin-side">
                                - Convenios con empresas de referencia en nuestros sectores formativos.
                            </li>
                            <li className = "margin-li margin-side">
                                - Un profesorado en contante formación.
                            </li>
                        </ul>
                        <div className = "bottom-line"/>
                    </div>
                </div>
            </div>
        </div>
    );
}