import Col from 'react-bootstrap/Col';
import { useState } from "react";
import React, { lazy, Suspense } from 'react';

export default function TagsOposicion(props) {
    const oposiciones = Object.values(props.oposiciones);
    const ResumenOposicion = lazy(() => import('../../general/ResumenOposicion.js'));
    const [showResumenOposicion, setshowResumenOposicion] = useState(false);
    const [oposicion_id, setOposicionId] = useState(false);
    
    const handleCloseModal = () => {
        setshowResumenOposicion(false);
    };
    if (props.error) {
        return <div>Error: {props.error.message}</div>;
    } else if (!props.isLoaded) {
        return <div>Loading...</div>;
    } else {
        const handleSeleccionOposicion = (evento) => {
            if(!isNaN(evento.currentTarget.value))
            {
                setOposicionId(evento.currentTarget.value);
                setshowResumenOposicion(true);        
            }
        };
        return (
            <>
                <Col md={props.colmd} xl={props.colxl}>
                    <div className="box-oppo p-4 h-100" style={{ backgroundImage: `url(${"https://gestionformativa.es/"+props.imagen})` }}>
                        <div className="mt-4 mb-4">
                            <h2>{props.titulo}</h2>
                            <p className="mb-2"><span className="text-primary">Del <b>02/01/2023</b> al <b>02/10/2023</b></span></p>
                            <div className="row">
                                <div className="col-lg-9 col-xl-6 mt-4">
                                    <div className="bg-primary text-white p-3 pt-2 pb-2 rounded-top">
                                        <i className="bi bi-arrow-down-circle"></i> Selecciona tu oposición
                                    </div>
                                    <div className="list-group oppositions-category-list">
                                    {oposiciones.map((oposicion) => (
                                        <button key={ oposicion.oposicion_id } onClick={handleSeleccionOposicion} value={ oposicion.oposicion_id } className="list-group-item list-group-item-action">
                                            <span dangerouslySetInnerHTML={{ __html: oposicion.nombre_oposicion }} />
                                            <i className="bi bi-arrow-right-circle-fill text-primary float-end"></i>
                                        </button>
                                    ))}
                                    </div>
                                    <div className="pt-4 text-primary">Porcentaje aprobados: <b>{props.porc_aprobados}%</b></div>
                                </div>
                                <div className="col-xl-6 text-end">
                                    <button type="button" className="btn nohover btn-warning promotion_price">{props.precio_final===props.precio_normal ? "Precio" : "Promoción"}:<br /><span className="h2"><b>{props.precio_final}€</b></span>{props.precio_final!==props.precio_normal ? <><br /><small>ANTES {props.precio_normal}€</small></> : ""}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                {showResumenOposicion ? (
                    <Suspense fallback={<div>Cargando...</div>}>
                        <ResumenOposicion
                            show={showResumenOposicion}
                            oposicion_id={oposicion_id}
                            handleClose={handleCloseModal}
                        />
                    </Suspense>
                ) : null}
            </>
        );
    }
}