import React from 'react';
export default function PruebaIndex() {

    var lastScrollTop = 0;

    window.addEventListener('scroll', function(e) {

        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (lastScrollTop > scrollTop && scrollTop === 0) {
            document.querySelector('.bottom-part').classList.add("first-of-type");
            document.querySelector('.top-part').classList.add("first-of-type");
        }
        else {
            if (document.querySelector('.bottom-part').classList.contains("first-of-type"))
                document.querySelector('.bottom-part').classList.remove("first-of-type");
            if (document.querySelector('.top-part').classList.contains("first-of-type"))
                document.querySelector('.top-part').classList.remove("first-of-type");
        }

        if (lastScrollTop !== scrollTop) {
            lastScrollTop = scrollTop;
        }

        var docHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight);
        var winHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        var offsetTop = document.getElementById('animatedText').offsetTop;
        var scrollPercent = scrollTop / (docHeight - (winHeight + offsetTop));
        var percentage = (scrollPercent * 100);

        document.querySelector('.bottom-part').style.transform = 'translateY(' + (- percentage) + '%)';
        document.querySelector('.top-part').style.transform = 'translateY(' + (percentage) + '%)';
    });

    let slides = null;

    React.useEffect(() => {

        // Asegúrate de seleccionar todos los elementos que deseas afectar
        slides = document.querySelectorAll('.slide');
        var slidetrack = document.querySelectorAll('.slide-track');

        slides.forEach((slide) => {
            slide.addEventListener('mouseover', function() {
                // Detén la animación
                if(slidetrack[0])
                    slidetrack[0].style.animation = '80s linear 0s infinite normal none paused scroll';
                // Muestra un borde alrededor del elemento
                this.style.boxShadow = 'inset 0 0 0 1.2vw var(--bs-primary)';
            });

            slide.addEventListener('mouseout', function() {
                // Reanuda la animación
                if(slidetrack[0])
                    slidetrack[0].style.animation = '80s linear 0s infinite normal none running scroll';
                // Quita el borde del elemento
                this.style.boxShadow = 'none';
            });
        });
    }, slides);

    return (
        <>
            <div className = "text-center pt-4 shadow-carousel" id = "animated-title-container">
                <div id = "animatedText" className = "text-container">
                    <div className = "text-part-container top-part animatedIm fadeInUpIm">Aprende </div>
                </div>
                <div id = "animatedText" className = "subtext-container">
                    <div className = "text-part-container bottom-part animatedIm fadeInDown">con FP-Prime</div>
                </div>
            </div>
            <div className = "text-center carousel-prueba">
                <div className = "explore-area__header">
                    <h2 className = "explore-area__title animatedIm fadeInUpIm">Tu centro formativo de confianza <strong></strong>
                    </h2>
                </div>
                <span className = "explore-area__line"></span>
                <div className = "slider">
                    <div className = "slide-track">
                        <div className = "slide align-content-center justify-content-center">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/classroom.webp")} alt = "Image1"/>
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/coding.webp")} alt = "Image2"/>
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/enfermero.webp")} alt = "Image3"/>
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/pencil.webp")} alt = "Image4"/>
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/people.webp")} alt = "Image5" />
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/student.webp")} alt = "Image6" />
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/student2.webp")} alt = "Image7" />
                            </div>
                        </div>

                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/classroom.webp")} alt = "Image1"/>
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/coding.webp")} alt = "Image2"/>
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/enfermero.webp")} alt = "Image3"/>
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/pencil.webp")} alt = "Image4"/>
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/people.webp")} alt = "Image5" />
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/student.webp")} alt = "Image6" />
                            </div>
                        </div>
                        <div className = "slide">
                            <div className = "slide-img">
                                <img src = {require("../img/carousel/auto/student2.webp")} alt = "Image7" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}